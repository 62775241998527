import * as React from 'react';
import { Props as LayoutProps } from './LastUpdatedLayout';
import { getDateRange } from '../../ReduxApp/app/filters/duck/selectors/ephemeral/getDateRange';
import { CompleteStore } from '../../ReduxApp/reducers/reducerTypes';
import { useDispatch, useSelector } from 'react-redux';
import { setDateRange } from '../../ReduxApp/app/filters/duck/operations';
import { getLastUpdated } from '../../ReduxApp/app/info/duck/selectors/ephemeral/getLastUpdated';

export type Props = {
	Layout: any;
};

export const LastUpdatedContainer = (props: Props) => {
	const dispatch = useDispatch();
	const currentDateRange = useSelector((state: CompleteStore) => getDateRange(state));
	const lastUpdated = useSelector((state: CompleteStore) => getLastUpdated(state));
	const updateDateToYesterday = () => {
		const newStartDate = currentDateRange.start.plus({ days: -1 });
		const newEndDate = currentDateRange.end.plus({ days: -1 });
		dispatch(setDateRange({ start: newStartDate, end: newEndDate }));
	};

	const render = () => {
		const Layout = props.Layout;
		const outgoingProps: LayoutProps = {
			lastUpdated,
			updateDateToYesterday,
		};
		const returnValue = <Layout {...outgoingProps} />;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
