import * as React from 'react';
import { Props as NumberCardContainerProps } from '../../../Components/NumberCard/NumberCardContainer';
import { NumberCardLayout } from '../../../Components/NumberCard/NumberCardLayout';
import { cssVarColors } from '../../../Constants/cssVarColors';
import { Props as BarContainerProps } from '../../../Components/BarCard/BarContainer';
import { BarLayout } from '../../../Components/BarCard/BarLayout';
import { colorKey } from '../../../Components/Common/ColorKey';
import { DateTime } from 'luxon';
import { StandardDistancePageLayout, Props as StandardDistancePageProps } from '../../../Components/StandardDistancePage/StandardDistancePageLayout';

export type Props = {
	totalVisits: number;
	todayVisits: number;
	recentVisits: number;
	similarVisits: number;
};

export const VisitorsDistanceLayout = (props: Props) => {
	const render = () => {
		const totalVisitsProps: NumberCardContainerProps = {
			identifier: 1,
			unit: 'Visits',
			count: props.totalVisits,
			title: 'Total Visits',
			Layout: NumberCardLayout,
			backgroundColor: cssVarColors.onSurface,
			flashColor: cssVarColors.highlight,
		};
		const todayVisits: NumberCardContainerProps = {
			identifier: 2,
			unit: 'Visits',
			count: props.todayVisits,
			title: 'Visits Today',
			Layout: NumberCardLayout,
			backgroundColor: cssVarColors.onSurfaceSecondary,
			flashColor: cssVarColors.highlight,
		};
		const barCardProps: BarContainerProps = {
			Layout: BarLayout,
			dataField: 'category',
			highlightCategories: ['Today'],
			data: [
				{
					tooltipExplanation: 'Total visits today',
					tooltipValue: 'Visits: {valueY}',
					tooltipBackground: cssVarColors.onSurfaceSecondary,
					category: 'Today',
					value: props.todayVisits,
				},
				{
					tooltipExplanation: 'Average visits over the last days',
					tooltipValue: 'Average Visits: {valueY}',
					tooltipBackground: cssVarColors.disable,
					category: 'Recent',
					value: props.recentVisits,
				},
				{
					tooltipExplanation: 'Average visits over the last ' + DateTime.now().toFormat('LLLL'),
					tooltipValue: 'Average Visits: {valueY}',
					tooltipBackground: cssVarColors.disable,
					category: 'Similar',
					value: props.similarVisits,
				},
			],
			chartDiv: 'contextChart',
			colorKey: colorKey,
		};
		const outgoingProps: StandardDistancePageProps = {
			numberContainerOneProps: totalVisitsProps,
			numberContainerTwoProps: todayVisits,
			barChartProps: barCardProps,
		};
		const returnValue = <StandardDistancePageLayout {...outgoingProps} />;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
