import { ActionNames, FiltersActionTypes, SET_DATE_RANGE_TYPE } from './actionTypes';
import { DateRangeType } from './storeTypes';

export const setDateRange = (dateTime: DateRangeType): FiltersActionTypes => {
	const returnValue: SET_DATE_RANGE_TYPE = {
		type: 'SET_DATE_RANGE',
		payload: {
			dateTime,
		},
	};
	return returnValue;
};

export const FiltersActions = {
	setDateRange,
};
