import * as React from 'react';
import { Props as LayoutProps } from '../../../Components/StandardVelocityCard/StandardVelocityLayout';
import { DateTime } from 'luxon';
import { setLoading } from '../../../ReduxApp/app/consts/duck/operations';
import { apiGet } from '../../../utils/axios/getWrapper';
import { reportingEndpoint } from '../../../Constants/endpoints';
import { setLastUpdated } from '../../../ReduxApp/app/info/duck/operations';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CompleteStore } from '../../../ReduxApp/reducers/reducerTypes';
import { getDateRange } from '../../../ReduxApp/app/filters/duck/selectors/ephemeral/getDateRange';
import { language } from './VisitorsVelocityLanguage';

export type Props = {
	Layout: any;
};

const refreshTimeout = 12000;

export const VisitorsVelocityContainer = (props: Props) => {
	// const hackedDate = "2022-04-17";
	const hackedDate = null;
	const dispatch = useDispatch();
	const [trendData, setTrendData] = useState();
	const dateRange = useSelector((state: CompleteStore) => getDateRange(state));
	const fetchData = async () => {
		setLoading(true);
		const params = {
			startDate: hackedDate ? hackedDate : dateRange.start.toISODate(),
			endDate: hackedDate ? hackedDate : dateRange.end.toISODate(),
		};
		const path = '/visits/velocity';
		try {
			const response = await apiGet(reportingEndpoint, path, params, false);
			const trendData = response.data.data.map((dataPoint: any) => {
				const returnValue = {
					date: new Date(dataPoint.date),
					total: dataPoint.total,
					recentTotal: dataPoint.recentTotal,
					similarTotal: dataPoint.similarTotal,
				};
				return returnValue;
			});
			setTrendData(trendData);
			dispatch(setLastUpdated(DateTime.now()));
		} catch (e) {
			console.log(`Error fetching from ${path}`, e);
		}
		setLoading(false);
	};

	useEffect(() => {
		fetchData();
		const interval = setInterval(() => {
			fetchData();
		}, refreshTimeout);
		return () => clearInterval(interval);
	}, [dateRange]);

	const render = () => {
		const Layout = props.Layout;
		const outgoingProps: LayoutProps = {
			language: language,
			primaryNumber: 1,
			secondaryNumber: 1,
			thirdNumber: 1,
			fourthNumber: 1,
			fifthNumber: 1,
			currentDate: DateTime.fromISO((hackedDate ? hackedDate : dateRange.start.toISODate()) + 'T00:00:00.000Z'),
			updateTime: DateTime.now().toISO(),
			trendData: trendData,
			goToYesterday: () => {},
			timeRange: 'goomba',
		};
		const returnValue = <Layout {...outgoingProps} />;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
