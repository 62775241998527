import * as React from 'react';
import { Props as LayoutProps } from './FilterOverlayLayout';
import { useState } from 'react';

export type Props = {
	Layout: any;
};

export const FilterOverlayContainer = (props: Props) => {
	const [areFiltersOpen, setAreFiltersOpen] = useState(false);
	const render = () => {
		const Layout = props.Layout;
		const outgoingProps: LayoutProps = {
			areFiltersOpen,
			setAreFiltersOpen,
		};
		const returnValue = <Layout {...outgoingProps} />;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
