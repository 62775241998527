import React from 'react';
import { Divider, Modal, PropTypes, Button } from '@mui/material';

import { ModalCSS, TitleWrapper, ContentWrapper, ButtonWrapper, ButtonContentWrapper, ButtonIcon, ButtonText } from './ModalStyles';
import { ButtonProps } from '@mui/material/Button';

export type VariantType = 'text' | 'outlined' | 'contained';

export type ButtonType = {
	text: any;
	variant?: VariantType;
	action: Function;
	color?: PropTypes.Color;
	disabled?: boolean;
	icon?: any;
};

export type OwnProps = {
	open: boolean;
	closeModal: Function;
	title: string;
	body: any;
	buttons: ButtonType[];
	width?: string;
	height?: string;
	top?: string;
};

export type Props = OwnProps;

type InlineStyle = {
	minHeight?: string;
	minWidth?: string;
	top?: string;
};

export const KidiupModal = (props: Props) => {
	let inlineStyle: InlineStyle = {};
	if (typeof props.width !== 'undefined') inlineStyle.minWidth = props.width;
	if (typeof props.height !== 'undefined') inlineStyle.minHeight = props.height;
	if (typeof props.top !== 'undefined') inlineStyle.top = props.top;

	const closeModal = () => {
		props.closeModal();
	};

	const render = (props: Props) => {
		const modalProps = {
			open: props.open,
			onClose: closeModal,
			'aria-labelledby': 'simple-modal-title',
			'aria-describedby': 'simple-modal-description',
		};
		const buttons = props.buttons.map((button: ButtonType, index: number) => {
			const key = 'modal' + '_button_' + index;
			const buttonProps: ButtonProps = {
				onClick: (e) => button.action(e),
				variant: typeof button.variant !== 'undefined' ? button.variant : 'contained',
				//@ts-ignore
				color: button.color,
				disabled: typeof button.disabled !== 'undefined' ? button.disabled : false,
				style: { marginLeft: '.5em', marginRight: '.5em' },
			};
			let returnValue = (
				<Button key={key} {...buttonProps}>
					{button.text}
				</Button>
			);
			if (button.icon)
				returnValue = (
					<Button key={key} {...buttonProps}>
						<ButtonContentWrapper>
							<ButtonIcon>{button.icon}</ButtonIcon>
							<ButtonText>{button.text}</ButtonText>
						</ButtonContentWrapper>
					</Button>
				);
			return returnValue;
		});

		return (
			<Modal {...modalProps}>
				<ModalCSS style={inlineStyle}>
					<TitleWrapper>{props.title}</TitleWrapper>
					<ContentWrapper>{props.body}</ContentWrapper>
					<Divider />
					<ButtonWrapper>{buttons}</ButtonWrapper>
				</ModalCSS>
			</Modal>
		);
	};

	const outerReturnValue = render(props);
	return outerReturnValue;
};

export default KidiupModal;
