import * as React from 'react';
import {IconWrapper, MessageWrapper, TextWrapper} from './SnackbarStyles';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export type Props = {
	message: string | React.ReactNode
};

export const MessageLayout = (props: Props) =>
{
	const render = () =>
	{
		const returnValue = (
			<MessageWrapper>
				<IconWrapper>
					<CheckCircleIcon/>
				</IconWrapper>
				<TextWrapper>
					{props.message}
				</TextWrapper>
			</MessageWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
