import * as React from 'react';
import { AllTractionWrapper, First, FirstColumn, FirstRow, SecondColumn, SecondRow } from './AllTractionStyles';
import { language } from './AllTractionLanguage';
import { NumberCardContainer, Props as NumberCardProps } from '../../../Components/NumberCard/NumberCardContainer';
import { NumberCardLayout } from '../../../Components/NumberCard/NumberCardLayout';
import { cssVarColors } from '../../../Constants/cssVarColors';

export type Props = {
	bookings: number;
	bookingRequests: number;
	bookingRequestsInitiated: number;
	visits: number;
	activityCenterViews: number;
	userSignUps: number;
	paidInvoices: number;
	activityClasses: number;
};

export const AllTractionLayout = (props: Props) => {
	const render = () => {
		const activityClasses: NumberCardProps = {
			identifier: 3,
			Layout: NumberCardLayout,
			count: props.activityClasses,
			title: 'Classes Booked',
			backgroundColor: cssVarColors.warning,
			flashColor: cssVarColors.highlight,
		};
		const bookingsCardProp: NumberCardProps = {
			identifier: 1,
			Layout: NumberCardLayout,
			count: props.bookings + 4, // the four are Nicole's child Harper's 2, and Nicole's child Carter's 2
			title: 'Transactions',
			backgroundColor: cssVarColors.warning,
			flashColor: cssVarColors.highlight,
		};
		const bookingsRequestedCardProp: NumberCardProps = {
			identifier: 1,
			Layout: NumberCardLayout,
			count: props.bookingRequests,
			title: 'Bookings Requested',
			backgroundColor: cssVarColors.onSurface,
			flashColor: cssVarColors.highlight,
			headerFontSize: '3em',
			contentFontSize: '6em',
		};
		const userSignUps: NumberCardProps = {
			identifier: 3,
			Layout: NumberCardLayout,
			count: props.userSignUps,
			title: 'User Sign Ups',
			backgroundColor: cssVarColors.onSurface,
			flashColor: cssVarColors.highlight,
			headerFontSize: '3em',
			contentFontSize: '6em',
		};
		const bookingsRequested: NumberCardProps = {
			identifier: 3,
			Layout: NumberCardLayout,
			count: props.bookingRequestsInitiated,
			title: 'Schedules Inspected',
			backgroundColor: cssVarColors.onSurfaceSecondary,
			flashColor: cssVarColors.highlight,
			headerFontSize: '2em',
			contentFontSize: '5em',
		};
		const activityCenterViews: NumberCardProps = {
			identifier: 3,
			Layout: NumberCardLayout,
			count: props.activityCenterViews,
			title: 'Activity Center Views',
			backgroundColor: cssVarColors.onSurfaceSecondary,
			flashColor: cssVarColors.highlight,
			headerFontSize: '1.5em',
			contentFontSize: '4em',
		};
		const visitsCardProp: NumberCardProps = {
			identifier: 3,
			Layout: NumberCardLayout,
			count: props.visits,
			title: 'KidiUp Visitors',
			backgroundColor: cssVarColors.onSurfaceSecondary,
			flashColor: cssVarColors.highlight,
			headerFontSize: '1.5em',
			contentFontSize: '3em',
		};
		const paidInvoices: NumberCardProps = {
			identifier: 3,
			Layout: NumberCardLayout,
			count: props.paidInvoices,
			title: 'Paid Invoices',
			backgroundColor: cssVarColors.onSurfaceSecondary,
			flashColor: cssVarColors.highlight,
		};
		const returnValue = (
			<AllTractionWrapper>
				<FirstColumn>
					<NumberCardContainer {...activityClasses} />
				</FirstColumn>
				<SecondColumn>
					<FirstRow>
						<NumberCardContainer {...bookingsCardProp} />
					</FirstRow>
					<SecondRow>
						<FirstColumn>
							<NumberCardContainer {...bookingsRequestedCardProp} />
						</FirstColumn>
						<SecondColumn>
							<FirstRow>
								<NumberCardContainer {...userSignUps} />
							</FirstRow>
							<SecondRow>
								<FirstColumn>
									<NumberCardContainer {...bookingsRequested} />
								</FirstColumn>
								<SecondColumn>
									<FirstRow>
										<NumberCardContainer {...activityCenterViews} />
									</FirstRow>
									<SecondRow>
										<NumberCardContainer {...visitsCardProp} />
									</SecondRow>
								</SecondColumn>
							</SecondRow>
						</SecondColumn>
					</SecondRow>
				</SecondColumn>
			</AllTractionWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
