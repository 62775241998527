import * as React from 'react';
import { cssVarColors } from '../../../Constants';
import { BarContainer, Props as BarContainerProps } from '../../../Components/BarCard/BarContainer';
import { BarLayout } from '../../../Components/BarCard/BarLayout';
import { colorKey } from '../../../Components/Common/ColorKey';
import { BottomRowWrapper, CardContainer, Title, TopRowCard, TopRowContentWrapper, TopRowWrapper, VisitorsDistanceWrapper } from '../../../Components/StandardDistancePage/StandardDistancePageStyles';
import { CategoryType } from '../../../Types/CategoryType';
import { generateCategoryCards } from './helpers/generateCategoryCards';
import { LastUpdatedContainer } from '../../../Components/LastUpdated/LastUpdatedContainer';
import { LastUpdatedLayout } from '../../../Components/LastUpdated/LastUpdatedLayout';
import { DateRangeType } from '../../../ReduxApp/app/filters/duck/storeTypes';

export type Props = {
	dateRange: DateRangeType;
	allCategories: Array<CategoryType>;
	totalVisits: any;
	todayVisits: any;
	recentVisits: any;
	similarVisits: any;
};

export const CategoriesDistanceLayout = (props: Props) => {
	const render = () => {
		const barCardProps: BarContainerProps = {
			Layout: BarLayout,
			data: {},
			chartDiv: 'contextChart',
			colorKey: colorKey,
		};
		const todayCardProps = {
			visits: props.todayVisits,
			allCategories: props.allCategories,
			backgroundColor: cssVarColors.onSurfaceSecondary,
		};
		const totalCardProps = {
			visits: props.totalVisits,
			allCategories: props.allCategories,
			backgroundColor: cssVarColors.onSurfaceSecondary,
		};
		const todayCards = generateCategoryCards(todayCardProps);
		const allTimeCards = generateCategoryCards(totalCardProps);
		const returnValue = (
			<VisitorsDistanceWrapper>
				<TopRowWrapper>
					<TopRowContentWrapper>
						<Title>{props.dateRange.start.toFormat('LLLL dd')}</Title>
						<CardContainer>{todayCards}</CardContainer>
					</TopRowContentWrapper>
					<TopRowContentWrapper>
						<Title>All Time</Title>
						<CardContainer>{allTimeCards}</CardContainer>
					</TopRowContentWrapper>
				</TopRowWrapper>
				<BottomRowWrapper>
					<BarContainer {...barCardProps} />
				</BottomRowWrapper>
				<LastUpdatedContainer Layout={LastUpdatedLayout} />
			</VisitorsDistanceWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
