import styled from 'styled-components';
import {Card} from "@mui/material";

export const MilestoneFunnelWrapper = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 1em;
`

export const TopRowWrapper = styled('div')`
	flex: 2;
	display: flex;
	flex-direction: row;
  margin-bottom: .25em;

`;
export const BottomRowWrapper = styled('div')`
	display: flex;
	flex-direction: row;
	flex: 2;
  margin-top: .25em;
  
`;
