import React from 'react';
import { RouteType } from '../types';
import { CategoriesDistanceContainer } from '../../../Pages/Categories/CategoriesDistance/CategoriesDistanceContainer';
import { CategoriesDistanceLayout } from '../../../Pages/Categories/CategoriesDistance/CategoriesDistanceLayout';

export const routes: Array<RouteType> = [
	{
		path: '/categories/distance',
		element: <CategoriesDistanceContainer Layout={CategoriesDistanceLayout} />,
	},
];
