import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {clearSnackbar} from "../../ReduxApp/app/snackbar/duck/actions";
import {IconButtonProps, Snackbar, SnackbarProps} from "@mui/material";
import {IconButton, Icon} from "@mui/material";
import {CompleteStore} from "../../ReduxApp/reducers/reducerTypes";
import {styled} from '@mui/material/styles';
import {MessageLayout} from "./MessageLayout";
import {EnhancedCloseIcon} from "./SnackbarStyles";

const EnhancedSnackbar = styled(Snackbar)`
`;

export const SuccessSnackbar = () =>
{
	const [localClose, setLocalClose] = useState(false);
	const dispatch = useDispatch();
	const snackbarOpen = useSelector(
		(state: CompleteStore) => state.snackbar.open
	);
	const snackbarMessage = useSelector(
		(state: CompleteStore) => state.snackbar.message
	);
	const handleClose = async () =>
	{
		setLocalClose(true);
		await dispatch(clearSnackbar());
		setLocalClose(false);
	}

	const iconButtonProps: IconButtonProps = {
		key: "close",
		"aria-label": "close",
		color: "inherit",
		onClick: handleClose,

	};
	const messageJSX = <MessageLayout message={snackbarMessage} />;

	useEffect(() => {
		setLocalClose(false)
	}, [snackbarMessage])

	const snackbarProps: SnackbarProps = {
			anchorOrigin: {
				vertical: "bottom",
				horizontal: "center"
			},
			open: snackbarOpen && !localClose,
			autoHideDuration: 4000,
			onClose: handleClose,
			"aria-describedby": "client-snackbar",
			message: messageJSX,
		color: "primary",
			action: [
				<IconButton {...iconButtonProps}>
					<EnhancedCloseIcon />
				</IconButton>
			]
		}
	;
	const returnValue = <EnhancedSnackbar {...snackbarProps}/>;
	return returnValue;
};
