import * as React from 'react';
import { FilterIcon, FilterOverlayWrapper } from './FilterOverlayStyles';
import { language } from './FilterOverlayLanguage';
import KidiupModal, { Props as ModalProps } from '../KidiupModal/ModalLayout';
import { useDispatch, useSelector } from 'react-redux';
import { getDateRange } from '../../ReduxApp/app/filters/duck/selectors/ephemeral/getDateRange';
import { CompleteStore } from '../../ReduxApp/reducers/reducerTypes';
import { useState } from 'react';
import { FilterModalLayout } from './Layouts/FilterModalLayout';
import { DateRangeType } from '../../ReduxApp/app/filters/duck/storeTypes';
import { setDateRange } from '../../ReduxApp/app/filters/duck/operations';
import { DateTime } from 'luxon';

export type Props = {
	areFiltersOpen: boolean;
	setAreFiltersOpen: (val: boolean) => void;
	setDateRange?: (startDate: any, endDate: any) => void;
};

export const FilterOverlayLayout = (props: Props) => {
	const dispatch = useDispatch();
	const currentDateRange = useSelector((state: CompleteStore) => getDateRange(state));
	const [newDateRange, setNewDateRange] = useState(currentDateRange);
	const render = () => {
		const newDateRangeUpdated = (newValue: DateTime) => {
			console.log('newValue is', newValue);
			const replacementDateRange: DateRangeType = {
				start: newValue,
				end: newValue,
			};
			setNewDateRange(replacementDateRange);
			dispatch(setDateRange(replacementDateRange));
		};
		const filterModalBodyProps = {
			newDateRangeUpdated,
			newDateRange,
		};
		const modalProps: ModalProps = {
			open: props.areFiltersOpen,
			closeModal: () => props.setAreFiltersOpen(false),
			title: 'Filters',
			body: <FilterModalLayout {...filterModalBodyProps} />,
			buttons: [],
		};
		const returnValue = (
			<>
				<FilterOverlayWrapper onClick={() => props.setAreFiltersOpen(!props.areFiltersOpen)}>
					<FilterIcon />
				</FilterOverlayWrapper>
				<KidiupModal {...modalProps} />
			</>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
