import React from 'react';
import ReactDOM from 'react-dom';
import { StyledEngineProvider } from '@mui/styled-engine-sc';
import { Provider as ReduxProvider } from 'react-redux';
import {SuccessSnackbar} from "./Components/SnackBar/SuccessSnackbar"
import './Assets/css/index.css';
import './Assets/css/override.css';
import { App } from './App';
import configureStore from './ReduxApp/store';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fal } from '@fortawesome/pro-light-svg-icons'
import {
	ThemeProvider,
	CssBaseline,
} from '@mui/material';
import {
	createTheme,
	responsiveFontSizes,
} from '@mui/material/styles';
import {cssVarColors} from "./Constants/cssVarColors";

library.add(fal)

const theme = {
	palette: {
		primary: {
			main: cssVarColors.onSurface
		}
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					color: '#fff',
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					color: '#fff',
				},
			},
		},
	}
}
const muiTheme = responsiveFontSizes(createTheme(theme));

const store = configureStore();
ReactDOM.render(
	<ReduxProvider store={store}>
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={muiTheme}>
				<App />
				<SuccessSnackbar />
			</ThemeProvider>
		</StyledEngineProvider>
	</ReduxProvider>,
	document.getElementById('root')
);
