export const contentWidth = '1280px';
export const smallContentWidth = '960px';
export const headerHeight = '64px';
export const headerFooterPadding = '8em';
export const mobileHeaderFooterPadding = '2em';
export const mobileFooterPadding = '1em';
export const mobileContentPadding = '2em';
export const blogMobileContentPadding = "1em"
export const mobileThreshold = 768;
export const smallThreshold = 1280;
export const drawerWidth = 240;
