import React from "react";
import {SNACKBAR_CLEAR, SNACKBAR_CLEAR_TYPE, SNACKBAR_SUCCESS, SNACKBAR_SUCCESS_TYPE} from "./actionTypes";

export const showSuccessSnackbar = (message: string | React.ReactNode): (dispatch: any) => SNACKBAR_SUCCESS_TYPE => {
	return dispatch => {
		const dispatchValue: SNACKBAR_SUCCESS_TYPE = {
			type: SNACKBAR_SUCCESS,
			payload: {message}
		};
		return dispatch(dispatchValue);
	};
};


export const clearSnackbar = (): (dispatch: any) => SNACKBAR_CLEAR_TYPE => {
	return dispatch => {
		const dispatchValue: SNACKBAR_CLEAR_TYPE = {
			type: SNACKBAR_CLEAR,
		};
		return dispatch(dispatchValue);
	};
};
