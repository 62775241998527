import * as React from 'react';
import { BottomRowWrapper, TopRowWrapper, VisitorsDistanceWrapper } from './StandardDistancePageStyles';
import { NumberCardContainer, Props as NumberCardContainerProps } from '../NumberCard/NumberCardContainer';
import { BarContainer, Props as BarContainerProps } from '../BarCard/BarContainer';
import { LastUpdatedContainer } from '../LastUpdated/LastUpdatedContainer';
import { LastUpdatedLayout } from '../LastUpdated/LastUpdatedLayout';

export type Props = {
	numberContainerOneProps: NumberCardContainerProps;
	numberContainerTwoProps: NumberCardContainerProps;
	barChartProps: BarContainerProps;
};

export const StandardDistancePageLayout = (props: Props) => {
	const render = () => {
		const returnValue = (
			<VisitorsDistanceWrapper>
				<TopRowWrapper>
					<NumberCardContainer {...props.numberContainerOneProps} />
				</TopRowWrapper>
				<BottomRowWrapper>
					<NumberCardContainer {...props.numberContainerTwoProps} />
					<BarContainer {...props.barChartProps} />
				</BottomRowWrapper>
				<LastUpdatedContainer Layout={LastUpdatedLayout} />
			</VisitorsDistanceWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
