import { cssVarColors } from '../../Constants';

export const getColumnTooltip = (tooltipHtml: any, target: any, props: any) => {
	console.log('props.data is', props.data);
	console.log('target.dataItem.categories.categoryX is', target.dataItem.categories.categoryX);
	const datapointProps = props.data.filter((datapoint: any) => datapoint.category === target.dataItem.categories.categoryX)[0];
	console.log('datapointProps is', datapointProps);
	let backgroundColor = datapointProps.tooltipBackgroundColor;
	let explanation = datapointProps.tooltipExplanation;
	let value = datapointProps.tooltipValue;
	const columnTooltip = `
      	<div style='height: 100%; width: 100%;background-color: ${backgroundColor}; color: #fff; font-size: 2em; text-align: left; padding: 1em;'>
			<div style="padding-top:top:.5em; padding-bottom:.5em;">
				${explanation}
			</div>
			<div style="padding-top:top:.5em; padding-bottom:.5em;">
				${value}
			</div>
		</div>
	  `;
	return columnTooltip;
};
