import styled from 'styled-components';
import { cssVarColors } from '../../Constants';

export const LastUpdatedWrapper = styled.div`
	color: ${cssVarColors.disable};
	font-size: 0.8em;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	padding-right: 1rem;
	padding-top: 1rem;
	padding-bottom: 1rem;
	cursor: pointer;
	&:hover {
		color: #fff;
	}
`;
