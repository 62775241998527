import { Card, CardContent, styled } from '@mui/material';
import { cssVarColors } from '../../Constants/cssVarColors';

export const CardEnhanced = styled(Card)`
	text-align: center;
	background-color: ${cssVarColors.dark};
	//background-color: #fff;
	animation-duration: 6s;
	margin: 0.25em;
	height: calc(100% - 0.5em);
	width: calc(100% - 0.5em);
	display: flex;
	flex-direction: column;
	border-radius: 15px;
	position: relative;
	flex: 1;
`;

export const CardContentEnhanced = styled(CardContent)`
	display: flex;
	flex-direction: column;
	height: 100%;
	align-items: center;
	justify-content: center;
`;
