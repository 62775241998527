import styled from 'styled-components';
import { cssVarColors } from '../../../Constants';

export const VisitorSessionsListingWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	color: #fff;
`;
export const SessionsWrapper = styled('div')`
	flex: 1;
	width: 100%;
	display: flex;
	flex-direction: column;
`;

export const SessionRowWrapper = styled('div')`
	display: flex;
	flex-direction: column;
	cursor: pointer;
	&:nth-child(odd) {
		background-color: ${cssVarColors.odd};
	}
	&:hover {
		background-color: ${cssVarColors.onSurface};
	}
`;

export const SessionInfoRow = styled('div')`
	display: flex;
	flex-direction: row;
`;

export const SessionsGenericCell = styled('div')`
	padding: 1em;
	flex: 1;
	&:last-of-type {
		text-align: right;
	}
`;

export const SessionsSessionIDCell = styled('div')`
	padding: 1em;
	text-align: right;
	flex: 2;
`;

export const ActivityLogTitleWrapper = styled('div')`
	padding-left: 1em;
	padding-top: 1em;
	padding-bottom: 0.25em;
`;

export const ActivityLogRowsWrapper = styled('div')`
	padding-left: 1em;
	padding-right: 1em;
`;

export const SessionActivityWrapper = styled('div')`
	display: flex;
	flex-direction: column;
	padding-bottom: 1em;
`;

export const SessionActivityRowWrapper = styled('div')`
	display: flex;
	flex-direction: row;
	&:nth-child(odd) {
		background-color: #00000022;
	}
`;

export const SessionGenericActivityCell = styled('div')`
	flex: 1;
	padding-left: 1em;
	padding-right: 1em;
	padding-top: 0.25em;
	padding-bottom: 0.25em;
`;

export const SessionDetailsActivityCell = styled('div')`
	flex: 3;
	padding-left: 1em;
	padding-right: 1em;
	padding-top: 0.25em;
	padding-bottom: 0.25em;
`;
