import * as React from 'react';
import { FilterModalWrapper } from '../FilterOverlayStyles';
import { language } from '../FilterOverlayLanguage';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Stack from '@mui/material/Stack';
import { TextField } from '@mui/material';
import { DateRangeType } from '../../../ReduxApp/app/filters/duck/storeTypes';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

export type Props = {
	newDateRangeUpdated: (e: any) => void;
	newDateRange: DateRangeType;
};

export const FilterModalLayout = (props: Props) => {
	const handleChange = (e: any) => {
		props.newDateRangeUpdated(e);
	};
	const render = () => {
		const returnValue = (
			<FilterModalWrapper>
				<LocalizationProvider dateAdapter={AdapterLuxon}>
					<Stack spacing={3}>
						<DesktopDatePicker label="Date" inputFormat="MM/dd/yyyy" value={props.newDateRange.start} onChange={handleChange} renderInput={(params) => <TextField {...params} />} />
					</Stack>
				</LocalizationProvider>
			</FilterModalWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
