export const colorKey = {
	'To Do': '#FFB5E8',
	'Selected for Development': '#FF9CEE',
	Progressing: '#C5A3FF',
	'Developer Testing': '#C5A3FF',
	Blocked: '#C5A3FF',
	'Code Review': '#C4FAF8',
	'Code Review Test': '#C4FAF8',
	'QA Phase': '#ACE7FF',
	'Product Accepted': '#ACE7FF',
	'Product Rejected': '#FFCBC1',
	Deployed: '#DBFFD6',
	'Deferred During Scoping': '#FFFFD1',
	'Deferred to Next Sprint': '#FFFFD1',
	'Deferred Indefinitely': '#FFFFD1',
	Resolve: '#BFFCC6',
	'/end': '#fff',
};

export type ColorKeyType = typeof colorKey;
export type ColorKeyIndiciesType = keyof ColorKeyType;
