import React from 'react';
import { Props as LayoutProps } from './NumberCardLayout';

export type Props = {
	key?: string;
	identifier: number;
	unit?: string;
	count: number;
	title: string;
	backgroundColor: string;
	flashColor: string;
	headerFontSize?: string;
	contentFontSize?: string;
	footerFontSize?: string;
	minWidth?: string;
	eyeMode?: boolean;
	setEyeContent?: (value: any) => void;
	setOpenModal?: (value: boolean) => void;
	modalHelp?: any;
	Layout: any;
	onClick?: () => void;
};

export const NumberCardContainer = (props: Props) => {
	const render = (props: Props) => {
		const Layout = props.Layout;
		const outgoingProps: LayoutProps = {
			identifier: props.identifier,
			unit: props.unit,
			count: props.count,
			title: props.title,
			backgroundColor: props.backgroundColor,
			flashColor: props.flashColor,
			headerFontSize: props.headerFontSize,
			footerFontSize: props.footerFontSize,
			contentFontSize: props.contentFontSize,
			minWidth: props.minWidth,
			setEyeContent: props.setEyeContent,
			setOpenModal: props.setOpenModal,
			eyeMode: props.eyeMode,
			modalHelp: props.modalHelp,
			onClick: props.onClick,
		};
		const returnValue = <Layout {...outgoingProps} />;
		return returnValue;
	};
	const outerReturnValue = render(props);
	return outerReturnValue;
};
