import { InfoActionTypes } from './actionTypes';
import { InfoActions } from './actions';
import { apiGet } from '../../../../utils/axios/getWrapper';
import { activityCategoriesEndpoint, localEndpoint } from '../../../../Constants/endpoints';
import { DateTime } from 'luxon';

type Dispatch = (action: InfoActionTypes) => void;
const serverEndpoint = true ? activityCategoriesEndpoint : localEndpoint;

export const fetchAvailableCategories = () => async (dispatch: Dispatch) => {
	dispatch(InfoActions.setLoading(true));
	const params = {};
	const path = '/category';
	try {
		let response = await apiGet(serverEndpoint, path, params);
		const sortedCategories = [...response.data.data].sort(compareCategories);
		const allCategories = [
			{
				name: 'All Activities',
				image: 'https://assets.kidiup.com/all.png',
			},
			...sortedCategories,
		];
		dispatch(InfoActions.setAvailableCategories(allCategories));
		dispatch(InfoActions.setLoading(false));
	} catch (e) {
		dispatch(InfoActions.setError(true));
	}
};

export const setLastUpdated = (last_updated: DateTime) => (dispatch: Dispatch) => {
	dispatch(InfoActions.setLastUpdated(last_updated));
};

const compareCategories = (a: any, b: any) => {
	if (a.name > b.name) return 1;
	else if (a.name < b.name) return -1;
	return 0;
};
