import * as React from 'react';
import {UserListingRowWrapper} from '../UserListingStyles';
import {UserListingFieldType} from "../../../../Schema/Users/userListing";
import {UserListingCellLayout} from "./UserListingCellLayout";

export type Props = {
	user: any;
	userSchema: Array<UserListingFieldType>
};

export const UserListingRowLayout = (props: Props) =>
{
	const render = () =>
	{
		const cells = generateRowCells(props.user, props.userSchema, UserListingCellLayout)
		const returnValue = (
			<UserListingRowWrapper>
				{cells}
			</UserListingRowWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};

const generateRowCells = (obj: any, schema: any, RenderObject: any) => {
	const returnValue = schema.map((schemaLine: any, index: number) => {
		const outgoingProps = {
			value: obj[schemaLine['source']],
			format: schemaLine['format'],
			align: schemaLine['align']
		};
		const innerReturnValue = (
			<RenderObject {...outgoingProps}/>
		);
		return innerReturnValue;
	});
	return returnValue;
}


