import styled from 'styled-components';
import { cssVarColors } from '../../Constants/cssVarColors';
import { mobileThreshold } from '../../Constants/layoutValues';

export const ModalCSS = styled('div')`
	background-color: #fff;
	top: 30%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	min-width: 450px;
	height: auto;
	min-height: 500px;
	border: 2px solid ${cssVarColors.onSurfaceSecondary};
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	outline: none;
`;

export const TitleWrapper = styled('div')`
	font-size: 1.5em;
	padding-left: 1em;
	padding-top: 1em;
	padding-bottom: 0.5em;
	background-color: ${cssVarColors.onSurfaceSecondary};
	color: #fff;
	@media (max-width: ${mobileThreshold}px) {
		padding-top: 0.5em;
		padding-bottom: 0.5em;
		font-size: 1.25em;
	} ;
`;

export const ContentWrapper = styled('div')`
	flex-grow: 1;
	padding: 1em;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
`;

export const ButtonWrapper = styled('div')`
	padding-top: 1em;
	padding-bottom: 3em;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	padding-right: 2.5em;
	@media (max-width: ${mobileThreshold}px) {
		padding-bottom: 1em;
		padding-right: 1em;
	} ;
`;

export const ButtonContentWrapper = styled('div')`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	@media (max-width: ${mobileThreshold}px) {
		font-size: 0.8em;
	} ;
`;

export const ButtonIcon = styled('div')`
	height: 100%;
	padding-right: 0.25em;
	display: flex;
`;

export const ButtonText = styled('div')`
	padding-left: 0.25em;
`;
