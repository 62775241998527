import * as React from 'react';
import { LastUpdatedWrapper } from './LastUpdatedStyles';
import { language } from './LastUpdatedLanguage';
import { DateTime } from 'luxon';

export type Props = {
	lastUpdated: DateTime | null;
	updateDateToYesterday: () => void;
};

export const LastUpdatedLayout = (props: Props) => {
	const render = () => {
		const returnValue = (
			<LastUpdatedWrapper onClick={props.updateDateToYesterday}>
				{language.lastUpdated} {props.lastUpdated ? props.lastUpdated.toFormat('LLLL dd - HH:mm') : '...Updating'}
			</LastUpdatedWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
