import { Card, CardContent, styled } from '@mui/material';

export const CardEnhanced = styled(Card)`
	text-align: center;
	background-color: #fff;
	color: #000;
	animation-duration: 6s;

	margin: 0.25em;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	border-radius: 15px;
`;

export const CardContentEnhanced = styled(CardContent)`
	display: flex;
	flex-direction: column;
	height: 100%;
	align-items: center;
	justify-content: center;
`;
