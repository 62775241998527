import styled from 'styled-components';

export const AllTractionWrapper = styled.div`
display: flex;
  flex-direction: row;
  min-height: 100vh;
  min-width: 100vw;
`

export const FirstColumn = styled('div')`
	flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: .25rem;
  padding-right: .25rem;
`;

export const SecondColumn = styled('div')`
	flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: .25rem;
  padding-right: .25rem;
`;

export const FirstRow = styled('div')`
	flex: 1;
  display: flex;
  flex-direction: row;
  padding-top: .25rem;
  padding-bottom: .25rem;
`;

export const SecondRow = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: row;
  padding-top: .25rem;
  padding-bottom: .25rem;
`;

export const First = styled('div')`
	flex: 1;
  height: 100%;
  min-height: 100%;
`;
