import * as React from 'react';
import { SessionInfoRow, SessionRowWrapper, SessionsGenericCell, SessionsSessionIDCell } from '../VisitorSessionsListingStyles';
import { SessionActivityRow } from './SessionActivityRow';
import { SessionActivity } from './SessionActivity';
import { cssVarColors } from '../../../../Constants';

export type Props = {
	session: any;
	active: boolean;
	onClick: () => void;
};

export const SessionRow = (props: Props) => {
	const render = () => {
		const activityProps = {
			activity: props.session.json_document.session_activity,
		};
		const style = props.active ? { backgroundColor: cssVarColors.highlight } : {};
		const activityContent = props.active ? <SessionActivity {...activityProps} /> : null;
		const wrapperProps = {
			onClick: props.onClick,
			style,
		};
		const returnValue = (
			<SessionRowWrapper {...wrapperProps}>
				<SessionInfoRow>
					<SessionsGenericCell>{props.session.start_timestamp}</SessionsGenericCell>
					<SessionsGenericCell>{props.session.portal}</SessionsGenericCell>
					<SessionsGenericCell>{props.session.user}</SessionsGenericCell>
					<SessionsGenericCell>{props.session.ip_address}</SessionsGenericCell>
					<SessionsGenericCell>{props.session.json_document.session_activity.length} Actions</SessionsGenericCell>
					<SessionsSessionIDCell>{props.session.session_id}</SessionsSessionIDCell>
				</SessionInfoRow>
				{activityContent}
			</SessionRowWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
