import * as React from 'react';
import { BookingListingHeaderCell, BookingListingHeaderRowWrapper } from '../BookingsListingStyles';
import { BookingsListingFieldType } from '../../../../Schema/Bookings/bookingsListing';

export type Props = {
	bookingsSchema: Array<BookingsListingFieldType>;
};

export const BookingsListingHeaderRowLayout = (props: Props) => {
	const render = () => {
		const innerContent = generateHeaderCells(props.bookingsSchema);
		const returnValue = <BookingListingHeaderRowWrapper>{innerContent}</BookingListingHeaderRowWrapper>;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};

const generateHeaderCells = (bookingsSchema: Array<BookingsListingFieldType>) => {
	const returnValue = bookingsSchema.map((item: BookingsListingFieldType, index: number) => {
		const align: 'left' | 'inherit' | '-moz-initial' | 'initial' | 'revert' | 'unset' | 'center' | 'end' | 'justify' | 'match-parent' | 'right' | 'start' = item.align ? item.align : 'left';
		const innerReturnValue = (
			<BookingListingHeaderCell style={{ textAlign: align }} key={'bookingslistingcell' + index}>
				{item.title}
			</BookingListingHeaderCell>
		);
		return innerReturnValue;
	});
	return returnValue;
};
