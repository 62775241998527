import React, { useRef, useEffect, useState } from 'react';
import { Card, CardContent, styled } from '@mui/material';
import { DateTime } from 'luxon';
import { cssVarColors } from '../../Constants';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { CardContentEnhanced, CardEnhanced } from './TrendCardStyles';

am4core.useTheme(am4themes_animated);
am4core.options.minPolylineStep = 15;

export type Props = {
	todayHeading: string;
	currentAverageHeading: string;
	dayAverageHeading: string;
	data: any;
	updateTime: string;
	currentDate: any;
};

am4core.unuseTheme(am4themes_animated);

const findMaxValue = (data: Record<string, any>[]) => {
	let max = 0;
	data?.forEach((row) => {
		const keys = Object.keys(row);
		keys.forEach((key) => {
			if (key !== 'date') {
				const value = row[key];
				if (!isNaN(value) && value > max) max = value;
			}
		});
	});
	const returnValue = Math.round((1.2 * max) / 10) * 10;
	return returnValue;
};

export const TrendCardLayout = (props: Props) => {
	const chartRef = useRef<am4charts.XYChart | null>(null);
	const seriesRef = useRef<am4charts.LineSeries | null>(null);
	const valueAxisRef = useRef<am4charts.ValueAxis<am4charts.AxisRenderer> | null>(null);
	const dateAxisRef = useRef<am4charts.DateAxis<am4charts.AxisRenderer> | null>(null);
	const dateAxisRangeRef = useRef<am4charts.DateAxisDataItem | null>(null);
	const [currentTimeRef, setCurrentTimeRef] = useState<am4charts.DateAxisDataItem | null>(null);

	useEffect(() => {
		if (!chartRef.current) {
			let chart = am4core.create('chartdiv', am4charts.XYChart);
			chart.cursor = new am4charts.XYCursor();
			chart.cursor.lineX.stroke = am4core.color('#8F3985');
			chart.cursor.lineX.strokeWidth = 4;
			chart.cursor.lineX.strokeOpacity = 0.2;
			chart.cursor.lineX.strokeDasharray = '';
			chart.cursor.lineY.stroke = am4core.color('#8F3985');
			chart.cursor.lineY.strokeWidth = 4;
			chart.cursor.lineY.strokeOpacity = 0.2;
			chart.cursor.lineY.strokeDasharray = '';
			chart.cursor.behavior = 'none';
			chart.paddingRight = 20;

			//      chart.data = props.data;
			chart.data = [];
			let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
			dateAxis.renderer.grid.template.location = 0;
			dateAxis.fontSize = 14;
			// dateAxis.title.text =
			//   'Time of Day - (' +
			//   DateTime.fromISO(props.currentDate).toFormat('yyyy LLL dd') +
			//   ')';
			dateAxis.title.fontSize = '2em';
			dateAxis.min = props.currentDate.toMillis();
			dateAxis.max = props.currentDate.plus({ day: 1 }).toMillis();
			dateAxis.groupData = true;
			dateAxis.baseInterval = {
				timeUnit: 'minute',
				count: 5,
			};
			// dateAxis.renderer.cellStartLocation = 0.1
			// dateAxis.renderer.cellEndLocation = 0.9
			dateAxisRef.current = dateAxis;

			let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
			if (valueAxis.tooltip) {
				valueAxis.tooltip.disabled = true;
			}
			valueAxis.renderer.minWidth = 35;
			valueAxis.min = 0;
			// valueAxis.max = findMaxValue(props.data);
			valueAxisRef.current = valueAxis;

			const guideStrokeWidth = 2;

			let seriesRecent = chart.series.push(new am4charts.LineSeries());
			seriesRecent.dataFields.dateX = 'date';
			seriesRecent.dataFields.valueY = 'recentTotal';
			seriesRecent.dataFields.valueYShow = 'value';
			seriesRecent.tooltipText = '{dateX} - 5 Weekday Average: {valueY.value}';
			seriesRecent.stroke = am4core.color(cssVarColors.onSurface);
			seriesRecent.fill = seriesRecent.stroke;
			seriesRecent.strokeWidth = guideStrokeWidth;
			seriesRecent.name = props.currentAverageHeading;
			seriesRecent.fillOpacity = 0.0;
			seriesRecent.propertyFields.strokeDasharray = 'lineDash';
			seriesRecent.interpolationDuration = 0;

			let seriesSimilar = chart.series.push(new am4charts.LineSeries());
			seriesSimilar.dataFields.dateX = 'date';
			seriesSimilar.dataFields.valueY = 'similarTotal';
			seriesSimilar.dataFields.valueYShow = 'value';
			seriesSimilar.tooltipText = '{dateX} - Five day Average: {valueY.value}';
			seriesSimilar.stroke = am4core.color(cssVarColors.primary);
			seriesSimilar.fill = seriesSimilar.stroke;
			seriesSimilar.strokeWidth = guideStrokeWidth;
			seriesSimilar.name = props.dayAverageHeading;
			seriesSimilar.fillOpacity = 0.0;
			seriesSimilar.propertyFields.strokeDasharray = 'lineDash';
			seriesSimilar.interpolationDuration = 0;

			let seriesToday = chart.series.push(new am4charts.LineSeries());
			seriesToday.dataFields.dateX = 'date';
			seriesToday.dataFields.valueY = 'total';
			seriesToday.dataFields.valueYShow = 'value';
			seriesToday.tooltipText = '{dateX} - Bowls Today: {valueY.value}';
			seriesToday.stroke = am4core.color(cssVarColors.highlight);
			seriesToday.strokeWidth = 5;
			seriesToday.name = props.todayHeading;
			seriesToday.fillOpacity = 0.25;
			seriesToday.fill = seriesToday.stroke;
			seriesToday.interpolationDuration = 0;

			seriesRef.current = seriesToday;

			let currentTime = dateAxis.axisRanges.create();
			dateAxisRangeRef.current = currentTime;
			setTimezone(currentTime);

			chart.legend = new am4charts.Legend();
			chart.legend.fontSize = '1.5em';
			chart.legend.useDefaultMarker = true;
			chart.legend.contentAlign = 'left';
			chart.legend.position = 'absolute';
			chart.legend.parent = chart.tooltipContainer;
			chart.legend.maxColumns = 1;
			chart.legend.dx = 140;
			chart.legend.dy = 100;

			chart.cursor = new am4charts.XYCursor();
			chartRef.current = chart;
			return () => {
				chart.dispose();
			};
		}
	}, [props.currentAverageHeading, props.dayAverageHeading, props.todayHeading]);

	useEffect(() => {
		const chart = chartRef.current;
		if (chart) {
			chart.data = props.data;
		}
		const maxValue = findMaxValue(props.data);
		if (valueAxisRef.current && valueAxisRef.current.max !== maxValue) {
			valueAxisRef.current.max = findMaxValue(props.data);
		}
		if (dateAxisRef.current && dateAxisRef.current.min !== props.currentDate.toMillis()) {
			dateAxisRef.current.min = props.currentDate.toMillis();
		}
		if (dateAxisRef.current && dateAxisRef.current.max !== props.currentDate.toMillis()) {
			dateAxisRef.current.max = props.currentDate.plus({ day: 1 }).toMillis();
		}
		if (dateAxisRef.current) {
			dateAxisRef.current.title.text = 'Time of Day - (' + DateTime.fromISO(props.currentDate).toFormat('LLLL dd, yyyy') + ')';
		}
		if (dateAxisRangeRef.current) {
			setTimezone(dateAxisRangeRef.current);
		}
	}, [props.currentDate, props.updateTime, props.data]);

	const setTimezone = (currentTime: am4charts.DateAxisDataItem) => {
		if (currentTime) {
			const offsetMultiplier = 20;
			const timezoneFontSize = '1.5em';
			const currentDate = new Date();
			let dx = 10;
			let horizontalCenter: am4core.HorizontalCenter = 'left';
			if (currentDate.getHours() > 12) {
				dx = -10;
				horizontalCenter = 'right';
			}
			currentTime.date = currentDate;
			currentTime.grid.stroke = am4core.color('#000000');
			currentTime.grid.strokeWidth = 2;
			currentTime.grid.strokeOpacity = 0.75;
			currentTime.grid.strokeDasharray = '25,10';
			currentTime.label.valign = 'top';
			currentTime.label.dx = dx;
			currentTime.label.dy = offsetMultiplier * 0;
			currentTime.label.text = currentDate.toTimeString();
			currentTime.label.inside = true;
			currentTime.label.fontSize = timezoneFontSize;
			currentTime.label.fillOpacity = 0.7;
			currentTime.label.horizontalCenter = horizontalCenter;
			setCurrentTimeRef(currentTime);
		}
	};

	return (
		<CardEnhanced>
			<CardContentEnhanced>
				<div
					id="chartdiv"
					style={{
						width: '100%',
						height: '100%',
					}}
				></div>
			</CardContentEnhanced>
		</CardEnhanced>
	);
};
