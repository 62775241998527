import React from 'react';
import { RouteType } from './types';
import { SitemapContainer } from '../../Pages/Sitemap/SitemapContainer';
import { SitemapLayout } from '../../Pages/Sitemap/SitemapLayout';
import {
	MilestoneRoutes,
	BookingsRoutes,
	CategoriesRoutes,
	NotificationsRoutes,
	UsersRoutes,
	VisitorsRoutes,
	TractionRoutes
} from './helpers';

export const productionRoutes: Array<RouteType> = [
	{
		path: '/',
		element: <SitemapContainer Layout={SitemapLayout} />,
		// element: <DashboardContainer Layout={DashboardLayout} />,
	},
	{
		path: '/sitemap',
		element: <SitemapContainer Layout={SitemapLayout} />,
	},
	...VisitorsRoutes,
	...BookingsRoutes,
	...CategoriesRoutes,
	...UsersRoutes,
	...NotificationsRoutes,
	...MilestoneRoutes,
	...TractionRoutes,
];

export const routes = productionRoutes;
