import React, { useRef, useEffect } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import { CardContentEnhanced, CardEnhanced } from './FunnelCardStyles';
import { ColorKeyType } from '../Common/ColorKey';
import { EyeModeFloater, Props as EyeModeFloaterProps } from '../Common/EyeModeFloater';
import {cssVarColors} from "../../Constants";
import {blend_colors} from "../../utils/blendColors";

export type Props = {
	colorKey?: ColorKeyType;
	data: any;
	updateTime?: string | null;
	chartDiv: string;
	eyeMode?: boolean;
	setEyeContent?: (value: any) => void;
	setOpenModal?: (value: boolean) => void;
	modalHelp?: any;
};

export const FunnelCardLayout = (props: Props) => {
	const chartRef = useRef<am5percent.SlicedChart | null>(null);
	const seriesRef = useRef<am5percent.FunnelSeries | null>(null);
	useEffect(() => {
		if (!chartRef.current) {
			let root = am5.Root.new(props.chartDiv);
			let chart = root.container.children.push(
				am5percent.SlicedChart.new(root, {})
			);
			chartRef.current = chart;

			let series = chart.series.push(
				am5percent.FunnelSeries.new(root, {
					name: "Series",
					categoryField: "name",
					valueField: "value",
					orientation: "vertical",
					bottomRatio: 1,
				})
			);
			series.labels.template.setAll({
				fontSize: "1.5em",
			})
			series.labels.template.set("text", "{category}: [bold]{value}[/]");
			series.links.template.setAll({
				height: 5,
			})
			series.slices.template.adapters.add("stroke", (stroke, target) => fillFunction(stroke, target));
			series.slices.template.adapters.add("fill", (fill, target) => fillFunction(fill, target));
			series.data.setAll(props.data);
			seriesRef.current = series;
		}
	});

	useEffect(() => {
		const series = seriesRef.current;
		if (series)
			series.data.setAll(props.data);
	}, [props.data]);

	const eyemodeProps: EyeModeFloaterProps = {
		eyeMode: props.eyeMode,
		setEyeContent: props.setEyeContent,
		setOpenModal: props.setOpenModal,
		modalHelp: props.modalHelp,
	};

	return (
		<CardEnhanced>
			<CardContentEnhanced>
				<div id={props.chartDiv} style={{ width: '100%', height: '100%' }}></div>
				<EyeModeFloater {...eyemodeProps} />
			</CardContentEnhanced>
		</CardEnhanced>
	);
};

const fillFunction = (fill: any, target: any) => {
	const blendedColor = blend_colors(cssVarColors.onPrimary, cssVarColors.highlight, (1 / target.dataItem.component._count) * (target.dataItem._settings.index + 1));
	const returnValue = am5.color(blendedColor);
	return returnValue;
};
