import * as React from 'react';
import {Props as LayoutProps} from "./LoadingOverlayLayout"
import {getLoading} from "../../ReduxApp/app/consts/duck/selectors/ephemeral/getLoading";
import {CompleteStore} from "../../ReduxApp/reducers/reducerTypes";
import {useSelector} from "react-redux";

export type Props = {
	Layout: any
};

export const LoadingOverlayContainer = (props: Props) =>
{
	const loadingState = useSelector((state: CompleteStore) => getLoading(state));
	const render = () =>
	{
		const Layout = props.Layout
		const outgoingProps: LayoutProps = {
		
		}
		const returnValue = loadingState ? <Layout {...outgoingProps} /> : null;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
