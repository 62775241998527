import styled from 'styled-components';
import { cssVarColors } from '../../../Constants';
import { Card, CardContent, Link } from '@mui/material';

export const BookingsListingWrapper = styled.div`
	background-color: ${cssVarColors.dark};
	display: flex;
	min-height: 100%;
	width: 100%;
	flex: 1;
	font-size: 1.4em;
`;

export const BookingsListingCard = styled(Card)`
	background-color: #000 !important;
	display: flex;
	flex-direction: column;
	min-width: 100%;
	flex: 1;
`;

export const BookingsListingCardContent = styled(CardContent)`
	color: #fff;
	padding: 1em;
`;

export const BookingListingHeaderRowWrapper = styled('div')`
	flex: 1;
	flex-direction: row;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1em;
`;

export const BookingListingHeaderCell = styled('div')`
	flex: 1;
`;

export const BookingListingRowWrapper = styled('div')`
	padding: 1em;
	flex: 1;
	flex-direction: row;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const BookingListingCellWrapper = styled('div')`
	flex: 1;
`;

export const EnhancedLink = styled(Link)`
	color: ${cssVarColors.secondaryBackground} !important;
`;

export const BookingsListingInfoWrapper = styled('div')`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

export const BookingsListingSwitchWrapper = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const JsonWrapper = styled('div')`
	height: 10em;
	width: 100%;
	border: 1px solid #ccc;
	font-size: 1em;
	overflow: auto;
	background-color: #000;
`;

export const HeaderWrapper = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: ${cssVarColors.onSurfaceSecondary};
`;

export const RowWrapper = styled('div')`
	&:nth-child(odd) {
		background-color: ${cssVarColors.odd};
	}
`;

export const CollapseIndicatorWrapper = styled('div')`
	padding: 1em;
	width: 1.4em;
`;

export const CollaspibleTitleWrapper = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: center;
	flex: 1;
	&:hover {
		background-color: ${cssVarColors.highlight};
	}
`;
