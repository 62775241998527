import { VisitorsDistanceContainer } from '../../../Pages/Visitors/VisitorsDistance/VisitorsDistanceContainer';
import { VisitorsDistanceLayout } from '../../../Pages/Visitors/VisitorsDistance/VisitorsDistanceLayout';
import React from 'react';
import { RouteType } from '../types';
import { VisitorSessionsListingContainer } from '../../../Pages/Visitors/VisitorSessionsListing/VisitorSessionsListingContainer';
import { VisitorSessionsListingLayout } from '../../../Pages/Visitors/VisitorSessionsListing/VisitorSessionsListingLayout';
import { VisitorsVelocityContainer } from '../../../Pages/Visitors/VisitorsVelocity/VisitorsVelocityContainer';
import { StandardVelocityLayout } from '../../../Components/StandardVelocityCard/StandardVelocityLayout';

export const routes: Array<RouteType> = [
	{
		path: '/visitors/distance',
		element: <VisitorsDistanceContainer Layout={VisitorsDistanceLayout} />,
	},
	{
		path: '/visitors/velocity',
		element: <VisitorsVelocityContainer Layout={StandardVelocityLayout} />,
	},
	{
		path: '/visitors/sessions',
		element: <VisitorSessionsListingContainer Layout={VisitorSessionsListingLayout} />,
	},
];
