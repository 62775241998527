import * as React from 'react';
import {
	DateTimeStampWrapper,
	IPAddressWrapper,
	WaterfallContentWrapper,
	WaterfallLineWrapper,
	UsernameWrapper, LevelWrapper
} from '../WaterfallStyles';
import {PlatformCell} from "./PlatformCell";
import {cssVarColors} from "../../../../Constants";
import {DateTime} from "luxon";

export type Props = {
	entry: any;
	setFilters: (filters: {}) => void;
};

export const WaterfallLine = (props: Props) =>
{
	const render = () =>
	{
		const datetimestamp = DateTime.fromISO(props.entry.datestamp + "T" + props.entry.timestamp);
		const notificationLevel = props.entry.level ? props.entry.level : "Notification";
		const rowBackgroundStyle = {backgroundColor: calculateBackgroundColor(notificationLevel)}
		const contentColor = calculateForegroundColor('content', props.entry.notification);
		const contentStyle = {color: contentColor};
		const returnValue = (
			<WaterfallLineWrapper style={rowBackgroundStyle}>
				<DateTimeStampWrapper>
					{datetimestamp.toFormat("LLL dd - hh:mm:ss")}
				</DateTimeStampWrapper>
				<LevelWrapper>
					{notificationLevel}
				</LevelWrapper>
				<LevelWrapper>
					{props.entry.action.replace("kidiup-", "").replace("-cdk-pipeline", "")}
				</LevelWrapper>
				<UsernameWrapper>
					{props.entry.username ? props.entry.username : "Anonymous"}
				</UsernameWrapper>
				<IPAddressWrapper>
					{props.entry.ipAddress}
				</IPAddressWrapper>
				<PlatformCell platform={props.entry.platform}/>
				<WaterfallContentWrapper style={contentStyle}>
					{props.entry.notification}
				</WaterfallContentWrapper>
			</WaterfallLineWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};

const calculateForegroundColor = (column: string, value: string) =>
{
	let returnValue = '#fff';
	if (column === 'content' && value === 'Failed')
		returnValue = cssVarColors.error;
	if (column === 'content' && value === 'Succeeded')
		returnValue = cssVarColors.onPrimary;
	return returnValue;
};

const calculateBackgroundColor = (level: string) => {
	let returnValue = "";
	switch (level)
	{
		case "Notification":
			returnValue = "";
			break;
		case "Warning":
			returnValue = cssVarColors.warning;
			break;
		case "Critical":
			returnValue = cssVarColors.error;
			break;
		case "Action Required":
			returnValue = cssVarColors.highlight;
			break;
		case "Deployment":
			returnValue = cssVarColors.primary;
			break;
	}
	// returnValue = cssVarColors.warning;
	return returnValue;
}
