import * as React from 'react';
import {LoadingOverlayWrapper} from './LoadingOverlayStyles';
import {language} from './LoadingOverlayLanguage'
import {CircularProgress} from "@mui/material";

export type Props = {

};

export const LoadingOverlayLayout = (props: Props) =>
{
	const render = () =>
	{
		const returnValue = (
			<LoadingOverlayWrapper>
				<CircularProgress />
			</LoadingOverlayWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
