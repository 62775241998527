import styled from 'styled-components';
import { Card } from '@mui/material';

export const VisitorsDistanceWrapper = styled('div')`
	width: 100%;
	flex: 1;
	display: flex;
	flex-direction: column;
	font-size: 0.4em;
`;

export const Title = styled('div')`
	text-align: center;
	padding-top: 0.25em;
	padding-bottom: 0.25em;
	font-size: 4em;
`;

export const TopRowWrapper = styled('div')`
	flex: 2;
	display: flex;
	flex-direction: row;
`;

export const TopRowContentWrapper = styled(Card)`
	flex: 1;
	margin: 0.25em;
	border-radius: 10px !important;
	background-color: #000 !important;
	padding-bottom: 5em;
`;

const cardDimension = 'min(30em, 13vw)';

export const CardContainer = styled('div')`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(${cardDimension}, ${cardDimension}));
	justify-content: center;
`;

export const TopRowCard = styled('div')`
	display: flex;
	height: ${cardDimension};
`;

export const BottomRowWrapper = styled('div')`
	display: flex;
	flex-direction: row;
	flex: 2;
`;
