import * as React from 'react';
import { Props as LayoutProps } from './MilestoneFunnelLayout';
import { useDispatch, useSelector } from 'react-redux';
import { CompleteStore } from '../../../ReduxApp/reducers/reducerTypes';
import { getAvailableCategories } from '../../../ReduxApp/app/info/duck/selectors/ephemeral/getAvailableCategories';
import { useEffect, useState } from 'react';
import { getDateRange } from '../../../ReduxApp/app/filters/duck/selectors/ephemeral/getDateRange';
import { setLoading } from '../../../ReduxApp/app/consts/duck/operations';
import { apiGet } from '../../../utils/axios/getWrapper';
import { reportingEndpoint } from '../../../Constants/endpoints';
import { setLastUpdated } from '../../../ReduxApp/app/info/duck/operations';
import { DateTime } from 'luxon';
import { string } from '@amcharts/amcharts4/core';

type orderingDataPoint = {
	originalString: string;
	printString: string;
};

export type Props = {
	Layout: any;
	ordering: Array<orderingDataPoint>;
};
const refreshTimeout = 12000;

export type funnelDataPoint = {
	name: string;
	value: number | string;
};

export const MilestoneFunnelContainer = (props: Props) => {
	const dispatch = useDispatch();
	const [rangeFunnelData, setRangeFunnelData] = useState(reorderFunnel([], props.ordering) as Array<funnelDataPoint>);
	const [allTimeFunnelData, setAllTimeFunnelData] = useState(reorderFunnel([], props.ordering) as Array<funnelDataPoint>);
	const dateRange = useSelector((state: CompleteStore) => getDateRange(state));
	const fetchData = async () => {
		setLoading(true);
		const params = {
			startDate: dateRange.start.toISODate(),
			endDate: dateRange.end.toISODate(),
		};
		console.log('params is', params);
		const path = '/milestones/funnel';
		try {
			const response = await apiGet(reportingEndpoint, path, params, true);
			const enhancedAllTimeFunnelData = reorderFunnel(response.data.data.allTimeFunnelData, props.ordering);
			const enhancedRangeFunnelData = reorderFunnel(response.data.data.rangeFunnelData, props.ordering);
			setAllTimeFunnelData(enhancedAllTimeFunnelData);
			setRangeFunnelData(enhancedRangeFunnelData);
			dispatch(setLastUpdated(DateTime.now()));
		} catch (e) {
			console.log(`Error fetching from ${path}`, e);
		}
		setLoading(false);
	};

	useEffect(() => {
		fetchData();
		const interval = setInterval(() => {
			fetchData();
		}, refreshTimeout);
		return () => clearInterval(interval);
	}, [dateRange]);

	const render = () => {
		const Layout = props.Layout;
		const outgoingProps: LayoutProps = {
			dateRange,
			rangeFunnelData,
			allTimeFunnelData,
		};
		const returnValue = <Layout {...outgoingProps} />;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};

const reorderFunnel = (funnelData: Array<funnelDataPoint>, funnelOrder: Array<orderingDataPoint>) => {
	const returnValue: Array<funnelDataPoint> = [];
	funnelOrder.forEach((orderItem: orderingDataPoint, index: number) => {
		const funnelEntry = funnelData.filter((dataPoint: any) => {
			const filterReturnValue = dataPoint.name === orderItem.originalString;
			return filterReturnValue;
		});
		const prefix = `Milestone ${index + 1} - `;
		if (funnelEntry.length === 1) returnValue.push({ name: prefix + orderItem.printString, value: parseInt(funnelEntry[0].value as string) });
		else returnValue.push({ name: prefix + orderItem.printString, value: 0 });
	});
	return returnValue;
};
