import axios from 'axios';
import { SnsActionTypes } from './actionTypes';
import { SnsActions } from './actions';
import { proxyUrl } from '../../../../Constants/env';

type Dispatch = (action: SnsActionTypes) => void;

export const throwSNS = (service: string, message: any) => async (dispatch: Dispatch) => {
	dispatch(SnsActions.snsInitialized());
	const url = proxyUrl;
	const payload = {
		service: service,
		message,
	};
	try {
		let response = await axios.post(url, payload);
		dispatch(SnsActions.snsComplete());
	} catch (error) {
		dispatch(SnsActions.errorSendingSNS(error));
	}
};
