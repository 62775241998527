import React from 'react';
import { RouteType } from '../types';
import {AllTractionContainer} from "../../../Pages/Traction/AllTraction/AllTractionContainer";
import {AllTractionLayout} from "../../../Pages/Traction/AllTraction/AllTractionLayout";

export const routes: Array<RouteType> = [
	{
		path: '/traction/allTraction',
		element: <AllTractionContainer Layout={AllTractionLayout} />,
	},
];
