import {DateTime} from "luxon";
import {SessionActionTypes, AvailableActions, SESSION_ACTION_ACTION} from './actionTypes';

export const markAsBusinessSessionAction = (currentPage: string = 'test'): SessionActionTypes => ({
	type: 'MARK_AS_BUSINESS_SESSION',
});

export const clearSession = (): SessionActionTypes => ({
	type: 'END_SESSION',
});

export const sessionAction = (action: AvailableActions, details: string, currentPage: string = 'test'): SessionActionTypes =>
{
	const returnValue: SESSION_ACTION_ACTION = {
		type: 'SESSION_ACTION',
		payload: {
			timestamp: DateTime.utc().toISO(),
			currentPage,
			action,
			details,
		},
	};
	return returnValue;
}

export const SessionActions = {
	clearSession,
	sessionAction,
};
