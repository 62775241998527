import * as React from 'react';
import { SessionsWrapper, VisitorSessionsListingWrapper } from './VisitorSessionsListingStyles';
import { language } from './VisitorSessionsListingLanguage';
import { SessionRow } from './Layouts/SessionRow';
import { useState } from 'react';
import { FilterOverlayContainer } from '../../../Components/FilterOverlay/FilterOverlayContainer';
import { FilterOverlayLayout } from '../../../Components/FilterOverlay/FilterOverlayLayout';

export type Props = {
	sessions: any;
};

export const VisitorSessionsListingLayout = (props: Props) => {
	const [activeSession, setActiveSession] = useState('');
	const toggleActiveSession = (sessionID: string) => {
		if (sessionID === activeSession) setActiveSession('');
		else setActiveSession(sessionID);
	};
	const render = () => {
		const returnValue = (
			<VisitorSessionsListingWrapper>
				<SessionsWrapper>{generateSessionRows(props.sessions, activeSession, toggleActiveSession)}</SessionsWrapper>
				<FilterOverlayContainer Layout={FilterOverlayLayout} />
			</VisitorSessionsListingWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};

const generateSessionRows = (sessions: Array<any>, activeSession: string, toggleActiveSession: (val: string) => void) => {
	const returnValue = sessions.map((session, index: number) => {
		const outgoingProps = {
			session: session,
			active: activeSession === session.session_id,
			onClick: () => {
				toggleActiveSession(session.session_id);
			},
		};
		const innerReturnValue = <SessionRow key={'sessionRow' + index} {...outgoingProps} />;
		return innerReturnValue;
	});
	return returnValue;
};
