import * as React from 'react';
import { SessionGenericActivityCell, SessionActivityRowWrapper, SessionDetailsActivityCell } from '../VisitorSessionsListingStyles';

export type Props = {
	action: any;
};

export const SessionActivityRow = (props: Props) => {
	const render = () => {
		const returnValue = (
			<SessionActivityRowWrapper>
				<SessionGenericActivityCell>{props.action.timestamp}</SessionGenericActivityCell>
				<SessionGenericActivityCell>{props.action.currentPage.indexOf('useLocation()') === -1 ? props.action.currentPage : 'Unknown Page Source'}</SessionGenericActivityCell>
				<SessionGenericActivityCell>{props.action.action}</SessionGenericActivityCell>
				<SessionDetailsActivityCell>{props.action.details}</SessionDetailsActivityCell>
			</SessionActivityRowWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
