import React from 'react';
import { RouteType } from '../types';
import { MilestoneFunnelContainer } from '../../../Pages/Milestones/MilestoneFunnel/MilestoneFunnelContainer';
import { MilestoneFunnelLayout } from '../../../Pages/Milestones/MilestoneFunnel/MilestoneFunnelLayout';
import { externalFunnelOrdering } from '../../ExternalFunnelOrdering';
import { internalFunnelOrdering } from '../../InternalFunnelOrdering';

export const routes: Array<RouteType> = [
	{
		path: '/milestones/externalFunnel',
		element: <MilestoneFunnelContainer Layout={MilestoneFunnelLayout} ordering={externalFunnelOrdering} />,
	},
	{
		path: '/milestones/internalFunnel',
		element: <MilestoneFunnelContainer Layout={MilestoneFunnelLayout} ordering={internalFunnelOrdering} />,
	},
];
