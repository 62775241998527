export const cssVarColorsInitial = {
	background: '#fcf4e7',
	secondaryBackground: '#f5f4ed',
	onBackground: '#000000',
	surface: '#ffffff',
	onSurface: '#4bc96e',
	onSurfaceRGB: '58, 71, 45',
	onSurfaceBlue: '#2D3A47',
	onSurfaceYellow: '#9d8f23',
	onSurfaceSecondary: '#2D3A47',
	onSurfaceLight: '#3ecc02',
	primary: '#62aef2',
	onPrimary: '#3a653a',
	primaryAlternative: '#62aef2',
	onPrimaryAlternative: '#74c378',
	secondary: '#fbe338',
	onSecondary: '#ffffff',
	secondaryAlternative: '#c8b52c',
	onSecondaryAlternative: '#ffffff',
	highlight: '#b65fcf',
	highlightLightHover: '#f2e6d0',
	highlightAlternative: '#f57e04',
	warning: '#f6a50a',
	error: '#e02020',
	disable: '#b5b5b5',
	dark: '#262626',
	placeholder: '#001c4c',
	odd: '#01278011',
	oddAlternate: '#3a653a11',
};

const SandraTurquoise = '#94B08B';

export const cssVarColors = {
	background: '#262626',
	secondaryBackground: '#f6f7f6',
	onBackground: '#000000',
	surface: '#000000',
	onSurface: '#227239',
	onSurfaceRGB: '58, 71, 45',
	onSurfaceSecondary: '#00748e',
	onSurfaceLight: SandraTurquoise,
	primary: '#0c4791',
	onPrimary: '#74c378',
	primaryAlternative: '#62aef2',
	onPrimaryAlternative: '#74c378',
	secondary: '#fbe338',
	onSecondary: '#ffffff',
	secondaryAlternative: '#c8b52c',
	onSecondaryAlternative: '#ffffff',
	highlight: '#9234ad',
	highlightAlternative: '#9966CC',
	warning: '#f6a50a',
	error: '#e02020',
	disable: '#b5b5b5',
	disabled: '#b5b5b5',
	dark: '#262626',
	gray: '#747474',
	placeholder: '#001c4c',
	odd: '#ffffff1a',
	oddAlternate: '#3a653a11',
};
