import styled from 'styled-components';

export const VelocityLayoutWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-top: 3px;
	//height: calc(100% - 3px);
	flex: 1;
`;

export const ContentWrapper = styled.div`
	flex-grow: 1;
	display: flex;
	flex-direction: row;
`;

export const PrimaryWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 3;
	margin-top: 0.25em;
	margin-bottom: 0.25em;
	margin-right: 0.25em;
`;

export const SecondaryWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	margin-top: 0.25em;
	margin-bottom: 0.25em;
	margin-left: 0.25em;
`;
