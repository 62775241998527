import * as React from 'react';
import { ActivityLogRowsWrapper, ActivityLogTitleWrapper, SessionActivityWrapper } from '../VisitorSessionsListingStyles';
import { SessionActivityRow } from './SessionActivityRow';

export type Props = {
	activity: Array<any>;
};

export const SessionActivity = (props: Props) => {
	const render = () => {
		const returnValue = (
			<SessionActivityWrapper>
				<ActivityLogTitleWrapper>Activity Log</ActivityLogTitleWrapper>
				<ActivityLogRowsWrapper>{generateActivityRows(props.activity)}</ActivityLogRowsWrapper>
			</SessionActivityWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};

const generateActivityRows = (activity: Array<any>) => {
	let returnValue = null;
	if (Array.isArray(activity)) {
		returnValue = activity.map((action: any, index: number) => {
			const outgoingProps = {
				action,
			};
			const innerReturnValue = <SessionActivityRow key={'action' + index} {...outgoingProps} />;
			return innerReturnValue;
		});
	}
	return returnValue;
};
