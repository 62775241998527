import * as React from 'react';
import { Props as LayoutProps } from './BookingsDistanceLayout';
import { useEffect, useState } from 'react';
import { userSessionsEndpoint } from '../../../Constants/endpoints';
import { setLoading } from '../../../ReduxApp/app/consts/duck/operations';
import { apiGet } from '../../../utils/axios/getWrapper';
import { DateTime } from 'luxon';

export type Props = {
	Layout: any;
};

export const BookingsDistanceContainer = (props: Props) => {
	const [totalBookings, setTotalBookings] = useState(0);
	const [todayBookings, setTodayBookings] = useState(0);
	const [recentBookings, setRecentBookings] = useState(0);
	const [similarBookings, setSimilarBookings] = useState(0);
	const fetchData = async () => {
		const path = '/bookings/count';
		setLoading(true);
		const params = {
			today: DateTime.now().toISODate(),
		};
		try {
			const response: any = await apiGet(userSessionsEndpoint, path, params, false);
			setTotalBookings(response.data.data.totalBookings);
			setTodayBookings(response.data.data.todayBookings);
			setRecentBookings(response.data.data.recentBookings);
			setSimilarBookings(response.data.data.similarBookings);
		} catch (e) {
			console.log(`Error fetching from ${path}`, e);
		}
		setLoading(false);
	};

	useEffect(() => {
		fetchData();
		setInterval(() => {
			fetchData();
		}, 12000);
	}, []);

	const render = () => {
		const Layout = props.Layout;
		const outgoingProps: LayoutProps = {
			totalBookings,
			todayBookings,
			recentBookings: parseFloat(recentBookings.toFixed(2)),
			similarBookings: parseFloat(similarBookings.toFixed(2)),
		};
		const returnValue = <Layout {...outgoingProps} />;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
