import styled from 'styled-components';
import {cssVarColors} from "../../../Constants";

export const WaterfallWrapper = styled.div`
  background-color: #000;
  width: 100%;
  color: #fff;
  padding-left: 1em;
  padding-bottom: 1em;
  padding-right: 1em;
  padding-top: 1em;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const WaterfallTable = styled.table`
  height: fit-content;
  width: 100%;
  border-collapse: collapse;
`;

export const WaterfallLineWrapper = styled('tr')`
  font-family: Courier, "Courier New", sans-serif;
  width: 100vw;
  animation-duration: 8s;
  &:nth-child(odd)
  {
    animation-name: newLinePop;
    @keyframes newLinePop {
      0% {
        background-color: ${cssVarColors.highlight};
      }
      25% {
        background-color: ${cssVarColors.highlight};
      }
      50% {
        background-color: ${cssVarColors.highlight};
      }
      100% {
        background-color: #000;
      }
    }
  }
  &:nth-child(even)
  {
    background-color: rgb(10,10,10);
    animation-name: newLinePopOdd;
    @keyframes newLinePopOdd {
      0% {
        background-color: ${cssVarColors.highlight};
      }
      25% {
        background-color: ${cssVarColors.highlight};
      }
      50% {
        background-color: ${cssVarColors.highlight};
      }
      100% {
        background-color: rgb(10,10,10);
      }
    }
  }
`;

export const DateTimeStampWrapper = styled('td')`
  padding-left: 1em;
  padding-right: 1em;
  color: ${cssVarColors.disable};
`;

export const LevelWrapper = styled('td')`
  padding-left: 1em;
  padding-right: 1em;
`;

export const PlatformCellWrapper = styled('td')`
  padding-left: 1em;
  padding-right: 1em;
`;

export const UsernameWrapper = styled('td')`
  padding-left: 1em;
  padding-right: 1em;
`;

export const IPAddressWrapper = styled('td')`
  padding-left: 1em;
  padding-right: 1em;
  color: ${cssVarColors.onSurface}
`;

export const WaterfallContentWrapper = styled('td')`
`;
