import {DateTime} from "luxon";

export const welcomeNotification = {
	"notification": "Welcome to Notification Waterfall",
	"ipAddress": "127.0.0.1",
	"action": "Waterfall Initiated",
	"level": "Notification",
	"platform": "Localhost",
	"timestamp": DateTime.now().toISOTime(),
	"datestamp": DateTime.now().toISODate(),
	"username": null,
}
