import React, { useState } from 'react';
import styled from 'styled-components';
import { Card, CardContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
// import { AnimatedText } from '../../Hivemind/HivemindStyles';

interface CardEnhancedProps {
	backgroundColor: string;
	identifier: number;
	flashColor: string;
	minWidth: string;
}

const CardEnhanced = styled(Card)<CardEnhancedProps & React.ComponentProps<typeof Card>>`
	&& {
		text-align: center;
		background-color: ${(p) => p.backgroundColor};
		min-width: ${(p) => p.minWidth};
		color: #fff;
		flex: 1;
		margin: 0.25em;
		width: 100%;
		display: flex;
		flex-direction: column;
		border-radius: 15px;

		@keyframes backgroundAnimation_${(p) => p.identifier} {
			0% {
				background-color: ${(p) => p.flashColor};
			}
			25% {
				background-color: ${(p) => p.flashColor};
			}
			50% {
				background-color: ${(p) => p.flashColor};
			}
			100% {
				background-color: ${(p) => p.backgroundColor};
			}
		}
	}
`;

const CardContentEnhanced = styled(CardContent)`
	display: flex;
	height: 100%;
	flex-direction: column;
`;

const NumberCardHeader = styled.div<{ headerFontSize: string }>`
	font-size: ${(p) => p.headerFontSize};
`;

const NumberCardContent = styled.div<{ contentFontSize: string }>`
	font-size: ${(p) => p.contentFontSize};
	position: relative;
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const NumberCardFooter = styled.div<{ footerFontSize: string }>`
	font-size: ${(p) => p.footerFontSize};
`;

const AnimatedText = styled.div`
	position: absolute;
	width: 100%;
	opacity: 0;

	@keyframes change-animation {
		0% {
			opacity: 1;
			transform: translate(0, 0);
		}
		100% {
			transform: translate(0, -400px);
			opacity: 0;
		}
	}
`;

const useStyles = makeStyles({
	AnimatedCardClass: (props: any) => ({
		animationName: 'backgroundAnimation_' + props.identifier,
		animationDuration: '6s',
	}),
	AnimatedTextClass: {
		animationDuration: '4s',
		animationName: 'change-animation',
	},
});

export type Props = {
	identifier: number;
	title: string;
	subtitle?: string;
	count: number;
	text?: string;
	unit?: string;
	backgroundColor: string;
	flashColor: string;
	contentFontSize?: string;
	headerFontSize?: string;
	footerFontSize?: string;
	minWidth?: string;
	eyeMode?: boolean;
	setEyeContent?: (value: any) => void;
	setOpenModal?: (value: boolean) => void;
	modalHelp?: any;
	onClick?: () => void;
	className?: string;
};

export const NumberCardLayout = (props: Props) => {
	const [lastCount, setLastCount] = useState(props.count);
	const [difference, setDifference] = useState(0);
	const [animating, setAnimating] = useState(false);
	if (props.count && props.count !== lastCount) {
		const roundedDifference = Math.round((props.count - lastCount) * 100) / 100;
		setDifference(roundedDifference);
		setLastCount(props.count);
		setAnimating(true);
	}
	const onAnimationEnd = () => {
		setAnimating(false);
	};
	const classes = useStyles(props);

	const contentFontSize = props.contentFontSize ? props.contentFontSize : '8em';
	const headerFontSize = props.headerFontSize ? props.headerFontSize : '3em';
	const footerFontSize = props.footerFontSize ? props.footerFontSize : '2em';
	const minWidth = props.minWidth ? props.minWidth : 'auto';

	const localClick = () => {
		if (props.eyeMode) {
			if (props.setEyeContent) props.setEyeContent(props.modalHelp);
			if (props.setOpenModal) props.setOpenModal(true);
		} else if (props.onClick) props.onClick();
	};

	const render = (props: Props) => {
		const cellClassName = animating ? classes.AnimatedCardClass : undefined;
		const textClassName = animating ? classes.AnimatedTextClass : undefined;
		const CardWrapper = {
			className: [cellClassName, props.className].join(' '),
			onAnimationEnd: onAnimationEnd,
		};
		const cardProps = {
			...CardWrapper,
			identifier: props.identifier,
			flashColor: props.flashColor,
			backgroundColor: props.backgroundColor,
			minWidth: minWidth,
			onClick: () => localClick(),
		};
		const returnValue = (
			<CardEnhanced {...cardProps}>
				<CardContentEnhanced>
					<NumberCardHeader headerFontSize={headerFontSize}>{props.title}</NumberCardHeader>
					<NumberCardContent contentFontSize={contentFontSize}>
						<AnimatedText className={textClassName}>{difference >= 0 ? '+' + numberWithCommas(difference) : numberWithCommas(difference)}</AnimatedText>
						{props.text ? props.text : numberWithCommas(props.count)}
					</NumberCardContent>
					<NumberCardFooter footerFontSize={footerFontSize}>{props.unit}</NumberCardFooter>
				</CardContentEnhanced>
			</CardEnhanced>
		);
		return returnValue;
	};
	const outerReturnValue = render(props);
	return outerReturnValue;
};

const numberWithCommas = (x: number) => {
	if (x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	return 0;
};
