import React from 'react';
import { RouteType } from '../types';
import { WaterfallContainer } from '../../../Pages/Notifications/Waterfall/WaterfallContainer';
import { WaterfallLayout } from '../../../Pages/Notifications/Waterfall/WaterfallLayout';

export const routes: Array<RouteType> = [
	{
		path: '/notifications/waterfall',
		element: <WaterfallContainer Layout={WaterfallLayout} />,
	},
];
