import * as React from 'react';
import {Props as LayoutProps} from "./UserListingLayout"
import {UserListingSchema} from "../../../Schema/Users/userListing";
import {useEffect, useState} from "react";
import {setLoading} from "../../../ReduxApp/app/consts/duck/operations";
import {apiGet} from "../../../utils/axios/getWrapper";
import {usersEndpoint} from "../../../Constants/endpoints";

export type Props = {
	Layout: any
};

export const UserListingContainer = (props: Props) =>
{
	const [users, setUsers] = useState([]);
	const fetchData = async () => {
		const endpoint = usersEndpoint;
		const path = "/allUsers";
		setLoading(true);
		const response: any = await apiGet(usersEndpoint, path, {}, true);
		setUsers(response.data.accounts);
		setLoading(false);
	};
	useEffect( () => {
		fetchData();
	}, []);

	const render = () =>
	{
		const Layout = props.Layout
		const outgoingProps: LayoutProps = {
			users,
			userSchema: UserListingSchema
		}
		const returnValue = <Layout {...outgoingProps} />;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
