import * as React from 'react';
import {Props as LayoutProps} from "./AllTractionLayout"
import {setLoading} from "../../../ReduxApp/app/consts/duck/operations";
import {apiGet} from "../../../utils/axios/getWrapper";
import {reportingEndpoint} from "../../../Constants/endpoints";
import {setLastUpdated} from "../../../ReduxApp/app/info/duck/operations";
import {DateTime} from "luxon";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CompleteStore} from "../../../ReduxApp/reducers/reducerTypes";
import {getDateRange} from "../../../ReduxApp/app/filters/duck/selectors/ephemeral/getDateRange";

export type Props = {
	Layout: any
};

export const AllTractionContainer = (props: Props) =>
{
	const [tractionData, setTractionData] = useState({} as any);
	const refreshTimeout = 60000
	const dispatch = useDispatch();
	const dateRange = useSelector((state: CompleteStore) => getDateRange(state));
	const fetchData = async () => {
		setLoading(true);
		const params = {
			startDate: dateRange.start.toISODate(),
			endDate: dateRange.end.toISODate(),
		};
		const path = '/traction/allTraction';
		try {
			const response = await apiGet(reportingEndpoint, path, params, true);
			setTractionData(response.data);
			dispatch(setLastUpdated(DateTime.now()));
		} catch (e) {
			console.log(`Error fetching from ${path}`, e);
		}
		setLoading(false);
	};

	useEffect(() => {
		fetchData();
		const interval = setInterval(() => {
			fetchData();
		}, refreshTimeout);
		return () => clearInterval(interval);
	}, [dateRange]);


	const render = () =>
	{
		const Layout = props.Layout
		const outgoingProps: LayoutProps = {
			bookings: tractionData.bookings ? tractionData.bookings : 0,
			bookingRequests: tractionData.bookingRequestSubmitted ? tractionData.bookingRequestSubmitted : 0,
			bookingRequestsInitiated: tractionData.requestBookingInitiated ? tractionData.requestBookingInitiated : 0,
			visits: tractionData.visits ? tractionData.visits : 0,
			activityCenterViews: tractionData.activityCenterView ? tractionData.activityCenterView : 0,
			userSignUps: tractionData.users ? tractionData.users : 0,
			paidInvoices: tractionData.invoices ? tractionData.invoices: 0,
			activityClasses: tractionData.activityClasses ? tractionData.activityClasses: 0
		}
		const returnValue = <Layout {...outgoingProps} />;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
