import { InfoActionTypes, SET_AVAILABLE_CATEGORIES_TYPE, SET_LOADING_TYPE, SET_ERROR_TYPE, SET_LAST_UPDATED_TYPE } from './actionTypes';
import { DateTime } from 'luxon';
import { CategoryType } from './storeTypes';

export const setLastUpdated = (last_updated: DateTime): InfoActionTypes => {
	const returnValue: SET_LAST_UPDATED_TYPE = {
		type: 'SET_LAST_UPDATED',
		payload: {
			last_updated,
		},
	};
	return returnValue;
};

export const setLoading = (loading: boolean): InfoActionTypes => {
	const returnValue: SET_LOADING_TYPE = {
		type: 'SET_LOADING',
		payload: {
			loading,
		},
	};
	return returnValue;
};

export const setError = (error: boolean): InfoActionTypes => {
	const returnValue: SET_ERROR_TYPE = {
		type: 'SET_ERROR',
		payload: {
			error,
		},
	};
	return returnValue;
};

export const setAvailableCategories = (availableCategories: Array<CategoryType>): InfoActionTypes => {
	const returnValue: SET_AVAILABLE_CATEGORIES_TYPE = {
		type: 'SET_AVAILABLE_CATEGORIES',
		payload: {
			availableCategories,
		},
	};
	return returnValue;
};
export const InfoActions = {
	setLastUpdated,
	setAvailableCategories,
	setLoading,
	setError,
};
