import axios from 'axios';
import { proxyUrl } from '../../Constants/env';
import { localEndpoint } from '../../Constants/endpoints';
import { isStaging } from '../isStaging';

export const apiGet = async (endpoint: string, path: string, params: any, proxy: boolean = true) => {
	if (proxy || !isStaging()) {
		const proxy = proxyUrl;
		const proxyParams = {
			...params,
			endpoint,
			path,
		};
		let response = await axios.get(proxy, { params: proxyParams });
		return response;
	} else {
		const url = localEndpoint + path;
		let response = await axios.get(url, { params: params });
		return response;
	}
};
