import * as React from 'react';
import { Props as LayoutProps } from './VisitorSessionsListingLayout';
import { setLoading } from '../../../ReduxApp/app/consts/duck/operations';
import { DateTime } from 'luxon';
import { apiGet } from '../../../utils/axios/getWrapper';
import { reportingEndpoint, userSessionsEndpoint } from '../../../Constants/endpoints';
import { useEffect, useState } from 'react';
import { getDateRange } from '../../../ReduxApp/app/filters/duck/selectors/ephemeral/getDateRange';
import { useSelector } from 'react-redux';
import { CompleteStore } from '../../../ReduxApp/reducers/reducerTypes';

export type Props = {
	Layout: any;
};

export const VisitorSessionsListingContainer = (props: Props) => {
	const [sessions, setSessions] = useState([]);
	const dateRange = useSelector((state: CompleteStore) => getDateRange(state));
	const fetchData = async () => {
		const path = '/visits/sessions/listing';
		setLoading(true);
		const params = {
			startDate: dateRange.start.toISODate(),
			endDate: dateRange.end.toISODate(),
		};
		try {
			const response: any = await apiGet(reportingEndpoint, path, params, true);
			setSessions(response.data.data);
		} catch (e) {
			console.log(`Error fetching from ${path}`, e);
		}
		setLoading(false);
	};

	useEffect(() => {
		fetchData();
		setInterval(() => {
			fetchData();
		}, 12000);
	}, [dateRange]);

	const render = () => {
		const Layout = props.Layout;
		const outgoingProps: LayoutProps = {
			sessions,
		};
		const returnValue = <Layout {...outgoingProps} />;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
