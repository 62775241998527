import * as React from 'react';
import Collapsible from 'react-collapsible';
import { useState } from 'react';
import { Switch } from '@mui/material';
import { BookingsListingWrapper, BookingsListingCard, BookingsListingCardContent, BookingsListingInfoWrapper, BookingsListingSwitchWrapper, JsonWrapper, RowWrapper, HeaderWrapper, CollapseIndicatorWrapper, CollaspibleTitleWrapper } from './BookingsListingStyles';
import { language } from './BookingsListingLanguage';
import { BookingsListingHeaderRowLayout } from './Layouts/BookingsListingHeaderRowLayout';
import { BookingsListingFieldType } from '../../../Schema/Bookings/bookingsListing';
import { LoadingOverlayContainer } from '../../../Components/LoadingOverlay/LoadingOverlayContainer';
import { LoadingOverlayLayout } from '../../../Components/LoadingOverlay/LoadingOverlayLayout';
import { BookingsListingRowLayout, Props as RowProps } from './Layouts/BookingsListingRowLayout';

export type Props = {
	bookings: Array<any>;
	bookingsSchema: Array<BookingsListingFieldType>;
};

export const BookingsListingLayout = (props: Props) => {
	const [hideKidiup, setHideKidiup] = useState(false);
	const render = () => {
		const outgoingProps = {
			bookingsSchema: props.bookingsSchema,
		};
		const bookingsRows = generateBookingsRows(props.bookings, props.bookingsSchema, hideKidiup);
		const returnValue = (
			<BookingsListingWrapper>
				<BookingsListingCard>
					<BookingsListingCardContent>
						<BookingsListingInfoWrapper>
							<>Total Bookings Showing: {bookingsRows.length}</>
							<BookingsListingSwitchWrapper>
								<>Hide @kidiup.com users</>
								<Switch color="warning" onChange={() => setHideKidiup((hideKidiup) => !hideKidiup)} />
							</BookingsListingSwitchWrapper>
						</BookingsListingInfoWrapper>
						<HeaderWrapper>
							<CollapseIndicatorWrapper></CollapseIndicatorWrapper>
							<BookingsListingHeaderRowLayout {...outgoingProps} />
						</HeaderWrapper>
						{bookingsRows}
					</BookingsListingCardContent>
				</BookingsListingCard>
				<LoadingOverlayContainer Layout={LoadingOverlayLayout} />
			</BookingsListingWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};

const generateBookingsRows = (bookings: Array<any>, bookingsSchema: Array<BookingsListingFieldType>, hideKidiup: boolean) => {
	if (hideKidiup) {
		bookings = bookings.filter((booking: any, index: number) => {
			return !booking['customer_email_address'].endsWith('@kidiup.com');
		});
	}
	const returnValue = bookings.map((booking: any, index: number) => {
		const outgoingProps: RowProps = {
			booking: booking,
			bookingsSchema: bookingsSchema,
		};
		const bookingRowClosed = (
			<CollaspibleTitleWrapper>
				<CollapseIndicatorWrapper> {'\u002B'} </CollapseIndicatorWrapper>
				<BookingsListingRowLayout {...outgoingProps} />
			</CollaspibleTitleWrapper>
		);
		const bookingRowOpen = (
			<CollaspibleTitleWrapper>
				<CollapseIndicatorWrapper> {'\u2212'} </CollapseIndicatorWrapper>
				<BookingsListingRowLayout {...outgoingProps} />
			</CollaspibleTitleWrapper>
		);
		const innerReturnValue = (
			<RowWrapper>
				<Collapsible trigger={bookingRowClosed} triggerWhenOpen={bookingRowOpen}>
					<JsonWrapper>
						<pre>${JSON.stringify(booking, null, 2)}</pre>
					</JsonWrapper>
				</Collapsible>
			</RowWrapper>
		);
		return innerReturnValue;
	});
	return returnValue;
};
