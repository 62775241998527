import * as React from 'react';
import { useState } from 'react';
import { Switch, FormGroup, FormControlLabel } from '@mui/material';
import { UserListingWrapper, UsersListingCard, UsersListingCardContent, UserListingInfoWrapper, UserListingSwitchWrapper } from './UserListingStyles';
import { language } from './UserListingLanguage';
import { UserListingHeaderRowLayout } from './Layouts/UserListingHeaderRowLayout';
import { UserListingFieldType } from '../../../Schema/Users/userListing';
import { LoadingOverlayContainer } from '../../../Components/LoadingOverlay/LoadingOverlayContainer';
import { LoadingOverlayLayout } from '../../../Components/LoadingOverlay/LoadingOverlayLayout';
import { UserListingRowLayout, Props as RowProps } from './Layouts/UserListingRowLayout';
import { endsWith } from 'lodash';

export type Props = {
	userSchema: Array<UserListingFieldType>;
	users: Array<any>;
};

export const UserListingLayout = (props: Props) => {
	const [hideKidiup, setHideKidiup] = useState(false);
	const render = () => {
		const outgoingProps = {
			userSchema: props.userSchema,
		};
		const userRows = generateUserRows(props.users, props.userSchema, hideKidiup);
		const returnValue = (
			<UserListingWrapper>
				<UsersListingCard>
					<UsersListingCardContent>
						<UserListingInfoWrapper>
							<>Total Users Showing: {userRows.length}</>
							<UserListingSwitchWrapper>
								<>Hide @kidiup.com users</>
								<Switch color="warning" onChange={() => setHideKidiup((hideKidiup) => !hideKidiup)} />
							</UserListingSwitchWrapper>
						</UserListingInfoWrapper>
						<UserListingHeaderRowLayout {...outgoingProps} />
						{userRows}
					</UsersListingCardContent>
				</UsersListingCard>
				<LoadingOverlayContainer Layout={LoadingOverlayLayout} />
			</UserListingWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};

const generateUserRows = (users: Array<any>, userSchema: Array<UserListingFieldType>, hideKidiup: boolean) => {
	if (hideKidiup) {
		users = users.filter((user: any, index: number) => {
			return !user['email_address'].endsWith('@kidiup.com');
		});
	}
	const returnValue = users.map((user: any, index: number) => {
		const outgoingProps: RowProps = {
			user: user,
			userSchema: userSchema,
		};
		const innerReturnValue = <UserListingRowLayout {...outgoingProps} />;
		return innerReturnValue;
	});
	return returnValue;
};
