import * as React from 'react';
import styled from 'styled-components';

export type Props = {
	eyeMode?: boolean;
	setEyeContent?: (value: any) => void;
	setOpenModal?: (value: boolean) => void;
	modalHelp: any;
};

const EyeModeFloaterWrapper = styled.div`
	position: absolute;
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0);
`;

export const EyeModeFloater = (props: Props) => {
	const localOnClick = () => {
		if (props.setEyeContent) props.setEyeContent(props.modalHelp ? props.modalHelp : { title: 'goomba', body: <div>goomba</div> });
		if (props.setOpenModal) props.setOpenModal(true);
	};

	const render = (props: Props) => {
		const overlayProps = {
			onClick: () => localOnClick(),
		};
		const returnValue = !props.eyeMode ? null : <EyeModeFloaterWrapper {...overlayProps}></EyeModeFloaterWrapper>;
		return returnValue;
	};
	const outerReturnValue = render(props);
	return outerReturnValue;
};
