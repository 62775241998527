import * as React from 'react';
import { BottomRowWrapper, MilestoneFunnelWrapper, TopRowWrapper } from './MilestoneFunnelStyles';
import { language } from './MilestoneFunnelLanguage';
import { FunnelCardLayout } from '../../../Components/FunnelCard/FunnelCardLayout';
import { FunnelCardContainer, Props as FunnelCardProps } from '../../../Components/FunnelCard/FunnelCardContainer';
import { BarContainer } from '../../../Components/BarCard/BarContainer';
import { NumberCardContainer, Props as NumberCardProps } from '../../../Components/NumberCard/NumberCardContainer';
import { cssVarColors } from '../../../Constants';
import { NumberCardLayout } from '../../../Components/NumberCard/NumberCardLayout';
import { Title } from '@mui/icons-material';
import { LastUpdatedContainer } from '../../../Components/LastUpdated/LastUpdatedContainer';
import { LastUpdatedLayout } from '../../../Components/LastUpdated/LastUpdatedLayout';
import { DateTime } from 'luxon';
import { DateRangeType } from '../../../ReduxApp/app/filters/duck/storeTypes';
import { FilterOverlayContainer } from '../../../Components/FilterOverlay/FilterOverlayContainer';
import { FilterOverlayLayout } from '../../../Components/FilterOverlay/FilterOverlayLayout';

export type Props = {
	rangeFunnelData: any;
	allTimeFunnelData: any;
	dateRange: DateRangeType;
};

const fromTheBottom = 4;
const terminalStage = 'Activity Center View';

export const MilestoneFunnelLayout = (props: Props) => {
	const render = () => {
		const allTimeFunnelProps: FunnelCardProps = {
			Layout: FunnelCardLayout,
			chartDiv: 'allTimeMilestoneFunnel',
			data: props.allTimeFunnelData,
		};
		const rangeFunnelProps: FunnelCardProps = {
			Layout: FunnelCardLayout,
			chartDiv: 'rangeMilestoneFunnel',
			data: props.rangeFunnelData,
		};
		let rangeCount: number = 1000000;
		let allTimeCount: number = 1000000;
		if (props.rangeFunnelData.length === 0) rangeCount = 0;
		else if (props.rangeFunnelData[props.rangeFunnelData.length - fromTheBottom].value !== 0) rangeCount = props.rangeFunnelData[0].value / props.rangeFunnelData[props.rangeFunnelData.length - fromTheBottom].value;

		if (props.allTimeFunnelData.length === 0) allTimeCount = 0;
		else if (props.allTimeFunnelData[props.allTimeFunnelData.length - fromTheBottom].value !== 0) {
			allTimeCount = props.allTimeFunnelData[0].value / props.allTimeFunnelData[props.allTimeFunnelData.length - fromTheBottom].value;
		}

		const allTimeBookingCostCardProps: NumberCardProps = {
			identifier: 1,
			unit: 'Dollars',
			count: parseFloat(allTimeCount.toFixed(2)),
			title: `Cost of ${terminalStage} (All Time)`,
			backgroundColor: cssVarColors.onSurface,
			flashColor: cssVarColors.highlight,
			Layout: NumberCardLayout,
		};
		const rangeBookingCostCardProps: NumberCardProps = {
			identifier: 1,
			unit: 'Dollars',
			count: parseFloat(rangeCount.toFixed(2)),
			title: `Cost of ${terminalStage} ${props.dateRange.start.toFormat('LLL dd')}`,
			backgroundColor: cssVarColors.onSurface,
			flashColor: cssVarColors.highlight,
			Layout: NumberCardLayout,
		};
		const returnValue = (
			<MilestoneFunnelWrapper>
				<TopRowWrapper>
					<FunnelCardContainer {...rangeFunnelProps} />
					<NumberCardContainer {...rangeBookingCostCardProps} />
				</TopRowWrapper>
				<BottomRowWrapper>
					<FunnelCardContainer {...allTimeFunnelProps} />
					<NumberCardContainer {...allTimeBookingCostCardProps} />
				</BottomRowWrapper>
				<LastUpdatedContainer Layout={LastUpdatedLayout} />
				<FilterOverlayContainer Layout={FilterOverlayLayout} />
			</MilestoneFunnelWrapper>
		);
		return returnValue;
	};

	const outerReturnValue = render();
	return outerReturnValue;
};
