import * as React from 'react';
import { Props as LayoutProps } from './CategoriesDistanceLayout';
import { useEffect, useState } from 'react';
import { setLoading } from '../../../ReduxApp/app/consts/duck/operations';
import { DateTime } from 'luxon';
import { apiGet } from '../../../utils/axios/getWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { CompleteStore } from '../../../ReduxApp/reducers/reducerTypes';
import { getAvailableCategories } from '../../../ReduxApp/app/info/duck/selectors/ephemeral/getAvailableCategories';
import { reportingEndpoint } from '../../../Constants/endpoints';
import { getDateRange } from '../../../ReduxApp/app/filters/duck/selectors/ephemeral/getDateRange';
import { setLastUpdated } from '../../../ReduxApp/app/info/duck/operations';

export type Props = {
	Layout: any;
};

export const CategoriesDistanceContainer = (props: Props) => {
	const dispatch = useDispatch();
	const refreshTimeout = 12000;
	const allCategories = useSelector((state: CompleteStore) => getAvailableCategories(state));
	const [totalVisits, setTotalVisits] = useState(0);
	const [todayVisits, setTodayVisits] = useState(0);
	const [recentVisits, setRecentVisits] = useState(0);
	const [similarVisits, setSimilarVisits] = useState(0);
	const dateRange = useSelector((state: CompleteStore) => getDateRange(state));
	const fetchData = async () => {
		setLoading(true);
		const params = {
			startDate: dateRange.start.toISODate(),
			endDate: dateRange.end.toISODate(),
		};
		console.log('params is', params);
		const path = '/categories/count';
		try {
			const response = await apiGet(reportingEndpoint, path, params, true);
			dispatch(setLastUpdated(DateTime.now()));
			setTotalVisits(response.data.data.totalVisits);
			setTodayVisits(response.data.data.todayVisits);
		} catch (e) {
			console.log(`Error fetching from ${path}`, e);
		}
		setLoading(false);
	};

	useEffect(() => {
		fetchData();
		const interval = setInterval(() => {
			fetchData();
		}, refreshTimeout);
		return () => clearInterval(interval);
	}, [dateRange]);

	const render = () => {
		const Layout = props.Layout;
		const outgoingProps: LayoutProps = {
			dateRange,
			allCategories,
			totalVisits,
			todayVisits,
			recentVisits,
			similarVisits,
		};
		const returnValue = <Layout {...outgoingProps} />;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
