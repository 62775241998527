import React, { useRef, useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_material from '@amcharts/amcharts4/themes/material';
import { CardEnhanced, CardContentEnhanced } from './BarStyles';
import { ColorKeyIndiciesType, ColorKeyType } from '../Common/ColorKey';
import { EyeModeFloater, Props as EyeModeFloaterProps } from '../Common/EyeModeFloater';
import { getColumnTooltip } from './BarTooltips';
import { cssVarColors } from '../../Constants';
import { toTitleCase } from '../../utils/toTitleCase';
import { AxisLabel } from '@amcharts/amcharts4/charts';

am4core.useTheme(am4themes_animated);
am4core.options.minPolylineStep = 15;

export type Props = {
	valueField: string | null;
	colorKey: ColorKeyType;
	data: any;
	updateTime: string;
	currentDate: any;
	chartDiv: string;
	dataField?: string;
	eyeMode?: boolean;
	setEyeContent?: (value: any) => void;
	setOpenModal?: (value: boolean) => void;
	modalHelp?: any;
	highlightCategories: Array<string>;
};

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_material);

export const BarLayout = (props: Props) => {
	const chartRef = useRef<am4charts.XYChart | null>(null);
	useEffect(() => {
		if (!chartRef.current) {
			const dataField = props.dataField ? props.dataField : 'assignee';
			const chart = am4core.create(props.chartDiv, am4charts.XYChart);
			chart.data = props.data;
			chartRef.current = chart;

			let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
			categoryAxis.dataFields.category = dataField;
			categoryAxis.renderer.grid.template.location = 0;
			categoryAxis.renderer.minGridDistance = 30;
			categoryAxis.renderer.labels.template.truncate = true;
			categoryAxis.renderer.labels.template.fontSize = '2em';
			categoryAxis.renderer.labels.template.fontWeight = '100';
			categoryAxis.renderer.labels.template.stroke = am4core.color('#fff');
			categoryAxis.renderer.grid.template.stroke = am4core.color('#eee');

			// categoryAxis.renderer.labels.template.rotation = -45;
			// categoryAxis.renderer.labels.template.horizontalCenter = 'right';
			categoryAxis.renderer.labels.template.verticalCenter = 'middle';
			// categoryAxis.renderer.labels.template.adapter.add("text", (text, target: AxisLabel) => {
			// 	return toTitleCase(text ? text : "");
			// });
			let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
			valueAxis.renderer.inside = false;
			valueAxis.renderer.labels.template.stroke = am4core.color('#fff');
			valueAxis.renderer.grid.template.stroke = am4core.color('#eee');
			valueAxis.renderer.labels.template.disabled = false;
			valueAxis.min = 0;

			let series = chart.series.push(new am4charts.ColumnSeries());
			series.dataFields.valueY = props.valueField ? props.valueField : 'value';
			series.dataFields.categoryX = 'category';
			series.name = 'Count';
			series.columns.template.tooltipText = '{categoryX}: [bold]{valueY}[/]';
			series.columns.template.fillOpacity = 0.8;
			series.columns.template.adapter.add('fill', (fill, target) => fillFunction(fill, target, props));
			series.columns.template.adapter.add('stroke', (fill, target) => fillFunction(fill, target, props));

			let columnTemplate = series.columns.template;
			columnTemplate.strokeWidth = 2;
			columnTemplate.strokeOpacity = 1;
			columnTemplate.adapter.add('tooltipHTML', (tooltipHtml, target) => getColumnTooltip(tooltipHtml, target, props));

			// chart.legend = new am4charts.Legend();
		}
	}, [props.chartDiv, props.colorKey, props.dataField, props.valueField, props.data]);

	useEffect(() => {
		const chart = chartRef.current;
		if (chart) {
			if (props.data) {
				const chartData = props.data;
				chart.data = chartData;
			}
			chart.invalidateData();
		}
	}, [props.data, props.updateTime, props.currentDate]);
	const eyemodeProps: EyeModeFloaterProps = {
		eyeMode: props.eyeMode,
		setEyeContent: props.setEyeContent,
		setOpenModal: props.setOpenModal,
		modalHelp: props.modalHelp,
	};

	return (
		<CardEnhanced>
			<CardContentEnhanced>
				<div id={props.chartDiv} style={{ width: '100%', height: '100%' }}></div>
				<EyeModeFloater {...eyemodeProps} />
			</CardContentEnhanced>
		</CardEnhanced>
	);
};

const fillFunction = (fill: any, target: any, props: Props) => {
	let returnValue = am4core.color(cssVarColors.disabled);
	if (props.highlightCategories.indexOf(target.dataItem.categories.categoryX) !== -1) returnValue = am4core.color(cssVarColors.onSurfaceSecondary);
	return returnValue;
};
