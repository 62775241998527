import * as React from 'react';
import {WaterfallTable, WaterfallWrapper} from './WaterfallStyles';
import {language} from './WaterfallLanguage'
import {WaterfallLine} from "./Layouts/WaterfallLine";
import {useEffect, useState} from "react";

export type Props = {
	streamEntries: Array<any>;
	filters: any;
	setFilters: (filters: {}) => void;
};

export const WaterfallLayout = (props: Props) =>
{
	const [atBottom, setAtBottom] = useState(true);
	useEffect(() => {
		window.scrollTo(window.scrollX, document.body.scrollHeight);
	}, )

	useEffect(() => {
		setAtBottom(window.scrollY === document.body.scrollHeight)
	}, [window.scrollY]);

	useEffect(() => {
		if (atBottom)
			window.scrollTo(window.scrollX, document.body.scrollHeight);
	}, [props.streamEntries.length])

	const render = () =>
	{
		const internalContent = generateStreamEntries(props.streamEntries, props.filters, props.setFilters);
		const returnValue = (
			<WaterfallWrapper>
				<WaterfallTable>
					{internalContent}
				</WaterfallTable>
			</WaterfallWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};


const generateStreamEntries = (streamEntries: Array<any>, filters: any, setFilters: (filters: {}) => void) => {
	const returnValue = streamEntries.map((entry: any, index: number) => {
		const outgoingProps = {
			entry,
			setFilters
		};
		const innerReturnValue = (
			<WaterfallLine {...outgoingProps}/>
				);
		return innerReturnValue;
	})
	return returnValue;
}
