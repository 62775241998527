import * as React from 'react';
import {Props as LayoutProps} from "./WaterfallLayout"
import {DateTime} from "luxon";
import {useEffect, useState} from "react";
import {notificationsEndpoint, usersEndpoint} from "../../../Constants/endpoints";
import {setLoading} from "../../../ReduxApp/app/consts/duck/operations";
import {apiGet} from "../../../utils/axios/getWrapper";
import {welcomeNotification} from "./WelcomeNotification";

export type Props = {
	Layout: any
};

export const WaterfallContainer = (props: Props) =>
{
	const [filters, setFilters] = useState({});
	const [streamEntries, setStreamEntries] = useState([welcomeNotification] as any);


	const fetchData = async () => {
		const path = "/";
		setLoading(true);
		try
		{
			const response: any = await apiGet(notificationsEndpoint, path, {}, true);
			if (response.data.success)
				setStreamEntries([welcomeNotification, ...response.data.data]);
			else
				setStreamEntries([welcomeNotification]);
		}
		catch (e)
		{
			// setStreamEntries([welcomeNotification]);
		}
		setLoading(false);
	};

	useEffect( () => {
		fetchData();
		setInterval(() => {fetchData()}, 5000)
	}, []);


	const render = () =>
	{
		const Layout = props.Layout
		const outgoingProps: LayoutProps = {
			streamEntries: streamEntries,
			setFilters,
			filters
		}
		const returnValue = <Layout {...outgoingProps} />;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
