import * as React from 'react';
import { Props as LayoutProps } from './UsersDistanceLayout';
import { useEffect, useState } from 'react';
import { userSessionsEndpoint } from '../../../Constants/endpoints';
import { setLoading } from '../../../ReduxApp/app/consts/duck/operations';
import { apiGet } from '../../../utils/axios/getWrapper';
import { DateTime } from 'luxon';

export type Props = {
	Layout: any;
};

export const UsersDistanceContainer = (props: Props) => {
	const [totalUsers, setTotalUsers] = useState(0);
	const [todayUsers, setTodayUsers] = useState(0);
	const [recentUsers, setRecentUsers] = useState(0);
	const [similarUsers, setSimilarUsers] = useState(0);
	const fetchData = async () => {
		const path = '/users/count';
		setLoading(true);
		const params = {
			today: DateTime.now().toISODate(),
		};

		try {
			const response: any = await apiGet(userSessionsEndpoint, path, params, false);
			setTotalUsers(response.data.data.totalUsers);
			setTodayUsers(response.data.data.todayUsers);
			setRecentUsers(response.data.data.recentUsers);
			setSimilarUsers(response.data.data.similarUsers);
		} catch (e) {
			console.log(`Error fetching from ${path}`, e);
		}
		setLoading(false);
	};

	useEffect(() => {
		fetchData();
		setInterval(() => {
			fetchData();
		}, 12000);
	}, []);

	const render = () => {
		const Layout = props.Layout;
		const outgoingProps: LayoutProps = {
			totalUsers,
			todayUsers,
			recentUsers: parseFloat(recentUsers.toFixed(2)),
			similarUsers: parseFloat(similarUsers.toFixed(2)),
		};
		const returnValue = <Layout {...outgoingProps} />;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
