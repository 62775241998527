import React from 'react';
import { RouteType } from '../types';
import { UserListingContainer } from '../../../Pages/Users/UserListing/UserListingContainer';
import { UserListingLayout } from '../../../Pages/Users/UserListing/UserListingLayout';
import { UsersDistanceContainer } from '../../../Pages/Users/UsersDistance/UsersDistanceContainer';
import { UsersDistanceLayout } from '../../../Pages/Users/UsersDistance/UsersDistanceLayout';

export const routes: Array<RouteType> = [
	{
		path: '/users/listing',
		element: <UserListingContainer Layout={UserListingLayout} />,
	},
	{
		path: '/users/distance',
		element: <UsersDistanceContainer Layout={UsersDistanceLayout} />,
	},
];
