export type UserListingFieldType = {
	title: string;
	source: string;
	format?: string;
	align?: 'left' | 'inherit' | '-moz-initial' | 'initial' | 'revert' | 'unset' | 'center' | 'end' | 'justify' | 'match-parent' | 'right' | 'start';
};

export const UserListingSchema: Array<UserListingFieldType> = [
	{
		title: 'Username',
		source: 'name',
	},
	{
		title: 'Email',
		source: 'email_address',
		format: 'email',
	},
	{
		title: 'Signed Up At',
		source: 'creation_timestamp',
		format: 'datetime',
		align: 'right',
	},
];
