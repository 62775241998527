import React from 'react';
import styled from 'styled-components';
import { NumberCardContainer } from '../NumberCard/NumberCardContainer';
import { NumberCardLayout } from '../NumberCard/NumberCardLayout';
import { TrendCardContainer } from '../TrendCard/TrendCardContainer';
import { TrendCardLayout } from '../TrendCard/TrendCardLayout';
import { FooterComponent } from '../Common/FooterComponent';
import { DateTime } from 'luxon';
import { language as languageDefault } from './StandardVelocityLanguage';
import { cssVarColors } from '../../Constants';
import { replaceWildcards } from '../../utils/replaceWildcards';
import { ContentWrapper, PrimaryWrapper, SecondaryWrapper, VelocityLayoutWrapper } from './StandardVelocityStyles';

type DeepPartial<T> = {
	[P in keyof T]?: DeepPartial<T[P]>;
};

export type Props = {
	primaryNumber: number;
	secondaryNumber: number;
	thirdNumber: number;
	fourthNumber: number;
	fifthNumber: number;
	trendData: any;
	currentDate: any;
	updateTime: string;
	goToYesterday: () => void;
	timeRange: string;
	language?: DeepPartial<typeof languageDefault>;
};

export const StandardVelocityLayout = (props: Props) => {
	let language = {
		...languageDefault,
		...props.language,
		trend: { ...languageDefault.trend, ...props.language?.trend },
	};
	const wildcards = [
		{
			replaceKey: '%day%',
			replaceValue: DateTime.fromISO(props.currentDate).toFormat('EEEE'),
		},
		{
			replaceKey: '%weekdayOrWeekend%',
			replaceValue: DateTime.fromISO(props.currentDate).weekday > 5 ? 'Weekends' : 'Weekdays',
		},
		{
			replaceKey: '%primaryUnit%',
			replaceValue: language.primaryUnit,
		},
		{
			replaceKey: '%shortUnit%',
			replaceValue: language.shortUnit,
		},
	];

	const render = (props: Props) => {
		const unifiedProps = {
			backgroundColor: cssVarColors.onSurface,
			flashColor: cssVarColors.highlight,
			headerFontSize: '1.5em',
			contentFontSize: '4em',
			footerFontSize: '1.25em',
			Layout: NumberCardLayout,
		};
		const primaryProps = {
			...unifiedProps,
			identifier: 1,
			backgroundColor: cssVarColors.highlight,
			flashColor: cssVarColors.onSurface,
			unit: replaceWildcards(language.primaryUnit, wildcards),
			count: props.primaryNumber,
			title: replaceWildcards(language.primaryTitle, wildcards),
		};
		const secondaryProps = {
			...unifiedProps,
			flashColor: cssVarColors.onSurface,
			identifier: 2,
			unit: replaceWildcards(language.secondaryUnit, wildcards),
			count: props.secondaryNumber,
			title: replaceWildcards(language.secondaryTitle, wildcards),
		};
		const thirdProps = {
			...unifiedProps,
			flashColor: cssVarColors.onSurface,
			identifier: 2,
			unit: replaceWildcards(language.thirdUnit, wildcards),
			count: props.thirdNumber,
			title: replaceWildcards(language.thirdTitle, wildcards),
		};
		const fourthProps = {
			...unifiedProps,
			flashColor: cssVarColors.onSurface,
			identifier: 2,
			unit: replaceWildcards(language.fourthUnit, wildcards),
			count: props.fourthNumber,
			title: replaceWildcards(language.fourthTitle, wildcards),
		};
		const fifthProps = {
			...unifiedProps,
			flashColor: cssVarColors.onSurface,
			identifier: 2,
			unit: replaceWildcards(language.fifthUnit, wildcards),
			count: props.fifthNumber,
			title: replaceWildcards(language.fifthTitle, wildcards),
		};
		const trendProps = {
			todayHeading: replaceWildcards(language.trend.todayHeading, wildcards),
			currentAverageHeading: replaceWildcards(language.trend.currentAverageHeading, wildcards),
			dayAverageHeading: replaceWildcards(language.trend.dayAverageHeading, wildcards),
			data: props.trendData,
			currentDate: props.currentDate,
			Layout: TrendCardLayout,
			updateTime: props.updateTime,
		};
		const returnValue = (
			<VelocityLayoutWrapper>
				<ContentWrapper>
					<PrimaryWrapper>
						<TrendCardContainer {...trendProps} />
					</PrimaryWrapper>
					<SecondaryWrapper>
						<NumberCardContainer {...primaryProps} />
						<NumberCardContainer {...secondaryProps} />
						<NumberCardContainer {...thirdProps} />
						<NumberCardContainer {...fourthProps} />
						<NumberCardContainer {...fifthProps} />
					</SecondaryWrapper>
				</ContentWrapper>
			</VelocityLayoutWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render(props);
	return outerReturnValue;
};
