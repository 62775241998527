import {
	ERROR_SENDING_ACTION,
	SnsActionTypes
} from './actionTypes';

export const snsInitialized = (): SnsActionTypes => ({
	type: 'SEND_SNS',
});

export const snsComplete = (): SnsActionTypes => ({
	type: 'SEND_SENT',
});

export const errorSendingSNS = (error: string): SnsActionTypes =>
{
	const returnValue: ERROR_SENDING_ACTION = {
		type: 'ERROR_SENDING',
		payload: {
			error
		},
	};
	return returnValue;
}

export const SnsActions = {
	snsInitialized,
	snsComplete,
	errorSendingSNS
};
