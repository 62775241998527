import * as React from 'react';
import {PlatformCellWrapper} from '../WaterfallStyles';
import { cssVarColors } from '../../../../Constants/cssVarColors';

export type Props = {
	platform: string;
};

export const PlatformCell = (props: Props) =>
{
	const render = () =>
	{
		let style = {};
		switch (props.platform)
		{
			case "Production":
				style = {color: cssVarColors.highlight}
				break;
			case "Staging":
				style = {color: cssVarColors.disable}
				break;
			case "Sonar - Staging":
				style = {color: cssVarColors.disable}
				break;
			case "Sonar - Production":
				style = {color: cssVarColors.highlightAlternative}
				break;
			case "App - Production":
				style = {color: cssVarColors.highlight}
				break;
			case "App - Staging":
				style = {color: cssVarColors.disable}
				break;
		}

		const outgoingProps = {
			style
		}

		const returnValue = (
			<PlatformCellWrapper {...outgoingProps}>
				{props.platform}
			</PlatformCellWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
