import * as React from 'react';
import { Props as NumberCardContainerProps } from '../../../Components/NumberCard/NumberCardContainer';
import { NumberCardLayout } from '../../../Components/NumberCard/NumberCardLayout';
import { cssVarColors } from '../../../Constants/cssVarColors';
import { Props as BarContainerProps } from '../../../Components/BarCard/BarContainer';
import { BarLayout } from '../../../Components/BarCard/BarLayout';
import { colorKey } from '../../../Components/Common/ColorKey';
import { DateTime } from 'luxon';
import { StandardDistancePageLayout, Props as StandardDistancePageProps } from '../../../Components/StandardDistancePage/StandardDistancePageLayout';

export type Props = {
	totalUsers: number;
	todayUsers: number;
	recentUsers: number;
	similarUsers: number;
};

export const UsersDistanceLayout = (props: Props) => {
	const render = () => {
		const totalUsersProps: NumberCardContainerProps = {
			identifier: 1,
			unit: 'Users',
			count: props.totalUsers,
			title: 'Total Users Registrations',
			Layout: NumberCardLayout,
			backgroundColor: cssVarColors.onSurface,
			flashColor: cssVarColors.highlight,
		};
		const todayUsers: NumberCardContainerProps = {
			identifier: 2,
			unit: 'Users',
			count: props.todayUsers,
			title: 'Users Registered Today',
			Layout: NumberCardLayout,
			backgroundColor: cssVarColors.onSurfaceSecondary,
			flashColor: cssVarColors.highlight,
		};
		const barCardProps: BarContainerProps = {
			Layout: BarLayout,
			dataField: 'category',
			highlightCategories: ['Today'],
			data: [
				{
					tooltipExplanation: 'Total registrations today',
					tooltipValue: 'Users: {valueY}',
					tooltipBackground: cssVarColors.onSurfaceSecondary,
					category: 'Today',
					value: props.todayUsers,
				},
				{
					tooltipExplanation: 'Average registrations over the last days',
					tooltipValue: 'Average User Registrations: {valueY}',
					tooltipBackground: cssVarColors.disable,
					category: 'Recent',
					value: props.recentUsers,
				},
				{
					tooltipExplanation: 'Average registrations over the last ' + DateTime.now().toFormat('LLLL'),
					tooltipValue: 'Average User Registrations: {valueY}',
					tooltipBackground: cssVarColors.disable,
					category: 'Similar',
					value: props.similarUsers,
				},
			],
			chartDiv: 'contextChart',
			colorKey: colorKey,
		};
		const outgoingProps: StandardDistancePageProps = {
			numberContainerOneProps: totalUsersProps,
			numberContainerTwoProps: todayUsers,
			barChartProps: barCardProps,
		};
		const returnValue = <StandardDistancePageLayout {...outgoingProps} />;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
