import axios from 'axios';
import {CompleteStore} from "../../../reducers/reducerTypes"

import {
	AvailableActions,
	SessionActionTypes
} from './actionTypes';
import { clearSession, markAsBusinessSessionAction, sessionAction } from './actions';
import {useLocation} from "react-router";
import ReactGA from "react-ga";

type Dispatch = (action: SessionActionTypes) => void;

export const markAsBusinessSession = (currentPage: string = 'currentPage') => async (dispatch: Dispatch) => {
	dispatch(markAsBusinessSessionAction(currentPage));
}

export const actionTaken = (action: AvailableActions, details: string, currentPage: string = "you need to put useLocation().pathname in the function call") => async (dispatch: Dispatch) => {
	ReactGA.event({
		category: action,
		action: details,
		label: currentPage
	})
	dispatch(sessionAction(action, details, currentPage));
}

export const endSession = (from?: string) => async (dispatch: Dispatch, getState: () => CompleteStore) => {
	const store = getState();
	const currentPage = 'page';
	const sessionStory = store.session;
	const currentDate = new Date();
	const endingState = {
		...sessionStory,
		session_endTime: currentDate,
		session_activity: sessionStory.session_activity.concat(
			{
				timestamp: currentDate.toISOString(),
				currentPage: currentPage,
				action: AvailableActions.END,
				details: "",
			},
		),
	};
	const body = {
		topic: 'app_sessions',
		message: endingState,
		session: endingState
	}
	try {
		// code to throw sns notification

		dispatch(clearSession());
	} catch (error) {
		dispatch(clearSession());
	}
}

