export type BookingsListingFieldType = {
	title: string;
	source: string;
	format?: string;
	align?: 'left' | 'inherit' | '-moz-initial' | 'initial' | 'revert' | 'unset' | 'center' | 'end' | 'justify' | 'match-parent' | 'right' | 'start';
};

export const BookingsListingSchema: Array<BookingsListingFieldType> = [
	{
		title: 'Customer',
		source: 'customer_name',
		align: 'center',
	},
	{
		title: 'Customer Email',
		source: 'customer_email_address',
		align: 'center',
	},
	{
		title: 'Child Name',
		source: 'childs_name',
		align: 'center',
	},
	{
		title: 'Booking Requested On',
		source: 'timestamp',
		format: 'datetime',
		align: 'center',
	},
	{
		title: 'Status',
		source: 'status',
		align: 'center',
	},
];
