export const externalFunnelOrdering = [
	{
		originalString: 'Session Started',
		printString: 'Session Started',
	},
	{
		originalString: 'Search Results',
		printString: 'Search Results',
	},
	{
		originalString: 'Activity Center Profile Viewed',
		printString: 'Activity Center Profile Viewed',
	},
	{
		originalString: 'Request Booking Initiated',
		printString: 'Schedule Inspected',
	},
	{
		originalString: 'Booking Request Submitted',
		printString: 'Booking Request Submitted',
	},
	{
		originalString: 'Activity Booked',
		printString: 'Activity Booked',
	},
];
