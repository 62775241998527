import * as React from 'react';
import {Props as LayoutProps} from "./FunnelCardLayout"
import {ColorKeyType} from "../Common/ColorKey";

export type Props = {
	Layout: any;
	data: any;
	colorKey?: ColorKeyType;
	updateTime?: string | null;
	chartDiv: string;
	eyeMode?: boolean;
	setEyeContent?: (value: any) => void;
	setOpenModal?: (value: boolean) => void;
	modalHelp?: any;
};

export const FunnelCardContainer = (props: Props) =>
{
	const render = () =>
	{
		const Layout = props.Layout
		const outgoingProps: LayoutProps = {
			colorKey: props.colorKey,
			data: props.data,
			updateTime: props.updateTime,
			chartDiv: props.chartDiv,
			setEyeContent: props.setEyeContent,
			setOpenModal: props.setOpenModal,
			eyeMode: props.eyeMode,
			modalHelp: props.modalHelp,
		};
		const returnValue = <Layout {...outgoingProps} />;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
