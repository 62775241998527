import React from 'react';
import { ColorKeyType } from '../Common/ColorKey';

export type Props = {
	Layout: any;
	data: any;
	chartDiv: string;
	colorKey: ColorKeyType;
	updateTime?: string | null;
	dataField?: string;
	valueField?: string | null;
	eyeMode?: boolean;
	setEyeContent?: (value: any) => void;
	setOpenModal?: (value: boolean) => void;
	modalHelp?: any;
	highlightCategories?: Array<string>;
};

export const BarContainer = (props: Props) => {
	const render = (props: Props) => {
		const Layout = props.Layout;
		const outgoingProps = {
			valueField: props.valueField,
			colorKey: props.colorKey,
			chartDiv: props.chartDiv,
			data: props.data,
			dataField: props.dataField,
			setEyeContent: props.setEyeContent,
			setOpenModal: props.setOpenModal,
			eyeMode: props.eyeMode,
			modalHelp: props.modalHelp,
			highlightCategories: props.highlightCategories,
		};
		const returnValue = <Layout {...outgoingProps} />;
		return returnValue;
	};
	const outerReturnValue = render(props);
	return outerReturnValue;
};
