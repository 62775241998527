import React from 'react';
import { RouteType } from '../types';
import { BookingsDistanceContainer } from '../../../Pages/Bookings/BookingsDistance/BookingsDistanceContainer';
import { BookingsDistanceLayout } from '../../../Pages/Bookings/BookingsDistance/BookingsDistanceLayout';
import { BookingsListingContainer } from '../../../Pages/Bookings/BookingsListing/BookingsListingContainer';
import { BookingsListingLayout } from '../../../Pages/Bookings/BookingsListing/BookingsListingLayout';

export const routes: Array<RouteType> = [
	{
		path: '/bookings/distance',
		element: <BookingsDistanceContainer Layout={BookingsDistanceLayout} />,
	},
	{
		path: '/bookings/listing',
		element: <BookingsListingContainer Layout={BookingsListingLayout} />,
	},
];
