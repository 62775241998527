import * as React from 'react';
import { Props as NumberCardContainerProps } from '../../../Components/NumberCard/NumberCardContainer';
import { NumberCardLayout } from '../../../Components/NumberCard/NumberCardLayout';
import { cssVarColors } from '../../../Constants/cssVarColors';
import { Props as BarContainerProps } from '../../../Components/BarCard/BarContainer';
import { BarLayout } from '../../../Components/BarCard/BarLayout';
import { colorKey } from '../../../Components/Common/ColorKey';
import { DateTime } from 'luxon';
import { StandardDistancePageLayout, Props as StandardDistancePageProps } from '../../../Components/StandardDistancePage/StandardDistancePageLayout';

export type Props = {
	totalBookings: number;
	todayBookings: number;
	recentBookings: number;
	similarBookings: number;
};

export const BookingsDistanceLayout = (props: Props) => {
	const render = () => {
		const totalBookingsProps: NumberCardContainerProps = {
			identifier: 1,
			unit: 'Bookings',
			count: props.totalBookings,
			title: 'Total Bookings Registrations',
			Layout: NumberCardLayout,
			backgroundColor: cssVarColors.onSurface,
			flashColor: cssVarColors.highlight,
		};
		const todayBookings: NumberCardContainerProps = {
			identifier: 2,
			unit: 'Bookings',
			count: props.todayBookings,
			title: 'Bookings Registered Today',
			Layout: NumberCardLayout,
			backgroundColor: cssVarColors.onSurfaceSecondary,
			flashColor: cssVarColors.highlight,
		};
		const barCardProps: BarContainerProps = {
			Layout: BarLayout,
			dataField: 'category',
			highlightCategories: ['Today'],
			data: [
				{
					tooltipExplanation: 'Total registrations today',
					tooltipValue: 'Bookings: {valueY}',
					tooltipBackground: cssVarColors.onSurfaceSecondary,
					category: 'Today',
					value: props.todayBookings,
				},
				{
					tooltipExplanation: 'Average registrations over the last days',
					tooltipValue: 'Average User Registrations: {valueY}',
					tooltipBackground: cssVarColors.disable,
					category: 'Recent',
					value: props.recentBookings,
				},
				{
					tooltipExplanation: 'Average registrations over the last ' + DateTime.now().toFormat('LLLL'),
					tooltipValue: 'Average User Registrations: {valueY}',
					tooltipBackground: cssVarColors.disable,
					category: 'Similar',
					value: props.similarBookings,
				},
			],
			chartDiv: 'contextChart',
			colorKey: colorKey,
		};
		const outgoingProps: StandardDistancePageProps = {
			numberContainerOneProps: totalBookingsProps,
			numberContainerTwoProps: todayBookings,
			barChartProps: barCardProps,
		};
		const returnValue = <StandardDistancePageLayout {...outgoingProps} />;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
