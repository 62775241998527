import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, RouteProps } from 'react-router-dom';

import { actionTaken } from './ReduxApp/app/user-sessions/duck/operations';
import { AvailableActions } from './ReduxApp/app/user-sessions/duck/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { getSession } from './ReduxApp/app/user-sessions/duck/selectors/ephemeral/getSession';
import { throwSNS } from './ReduxApp/app/sns/duck/operations';
import { CompleteStore } from './ReduxApp/reducers/reducerTypes';
import {routes} from './Schema/Routes/general';
import {RouteType} from "./Schema/Routes/types";
import {setWindowWidth, setWindowHeight} from "./ReduxApp/app/consts/duck/operations";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import {fetchAvailableCategories} from "./ReduxApp/app/info/duck/operations";
import ReactGA from "react-ga";
import {googleID} from "./Constants/env";

const generateRoutes = (routes: Array<RouteType>) => {
	const returnValue = routes.map((route, index: number) =>
	{
		const outgoingProps: RouteProps = {
			path: route.path,
			//@ts-ignore
			element: route.element,
		};
		const innerReturnValue = <Route key={'route' + index} {...outgoingProps} />;
		return innerReturnValue;
	});
	return returnValue
};

const calculateWidth = () => {
	const returnValue = window.innerWidth < window.outerWidth ? window.innerWidth : window.outerWidth;
	return returnValue;
}

const calculateHeight = () => {
	const returnValue = window.innerHeight < window.outerHeight ? window.innerHeight : window.outerHeight;
	return returnValue;
}

export const App = () => {
	ReactGA.initialize(googleID, {debug: false});
	ReactGA.pageview(window.location.pathname + window.location.search);
	const dispatch = useDispatch();
	const handleWindowSizeChange = () => {
		dispatch(setWindowWidth(calculateWidth()))
		dispatch(setWindowHeight(calculateHeight()))
	};
	const session = useSelector((state: CompleteStore) => getSession(state));
	useEffect(() => {
		if (session.session_id !== '') {
			dispatch(throwSNS("user-sessions", session));
		} else {
		}
	}, [session]);

	useEffect(() => {
		dispatch(actionTaken(AvailableActions.BEGUN, 'Session Started'));
	}, []);

	useEffect(() => {
		dispatch(setWindowWidth(calculateWidth()))
		dispatch(setWindowHeight(calculateHeight()))
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		}
	}, []);

	useEffect( () => {
		dispatch(fetchAvailableCategories())
	})

	const returnValue = (
			<Router>
				<ScrollToTop>
					<Routes>
						{generateRoutes(routes)}
					</Routes>
				</ScrollToTop>
			</Router>
	);
	return returnValue;
};
