import { CategoryType } from '../../../../Types/CategoryType';
import { NumberCardContainer, Props as NumberCardContainerProps } from '../../../../Components/NumberCard/NumberCardContainer';
import { NumberCardLayout } from '../../../../Components/NumberCard/NumberCardLayout';
import { cssVarColors } from '../../../../Constants';
import { TopRowCard } from '../../../../Components/StandardDistancePage/StandardDistancePageStyles';
import * as React from 'react';

export type Props = {
	allCategories: Array<CategoryType>;
	backgroundColor: string;
	visits: any;
};

export const generateCategoryCards = (props: Props) => {
	const returnValue = props.allCategories.map((category: CategoryType, index: number) => {
		const outgoingProps: NumberCardContainerProps = {
			identifier: index,
			unit: 'Clicks',
			count: props.visits && props.visits[category.name] ? props.visits[category.name] : 0,
			title: category.name,
			Layout: NumberCardLayout,
			backgroundColor: props.backgroundColor,
			flashColor: cssVarColors.highlight,
		};
		const innerReturnValue = (
			<TopRowCard>
				<NumberCardContainer key={'category' + index} {...outgoingProps} />
			</TopRowCard>
		);
		return innerReturnValue;
	});
	return returnValue;
};
