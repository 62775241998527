import * as React from 'react';
import { EnhancedLink, BookingListingCellWrapper } from '../BookingsListingStyles';
import { DateTime } from 'luxon';
import { Link } from '@mui/material';

export type Props = {
	value: string;
	format?: string;
	align?: 'left' | 'inherit' | '-moz-initial' | 'initial' | 'revert' | 'unset' | 'center' | 'end' | 'justify' | 'match-parent' | 'right' | 'start';
};

export const BookingsListingCellLayout = (props: Props) => {
	const render = () => {
		let value: string | React.ReactNode = props.value;
		switch (props.format) {
			case 'datetime': {
				value = DateTime.fromISO(props.value).toFormat('LLLL dd, yyyy - HH:mm');
				break;
			}
			case 'email': {
				value = <EnhancedLink href={'mailto:' + props.value}>{props.value}</EnhancedLink>;
				break;
			}
		}
		const align: 'left' | 'inherit' | '-moz-initial' | 'initial' | 'revert' | 'unset' | 'center' | 'end' | 'justify' | 'match-parent' | 'right' | 'start' = props.align ? props.align : 'left';
		const returnValue = <BookingListingCellWrapper style={{ textAlign: align }}>{value}</BookingListingCellWrapper>;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
