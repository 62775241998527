import * as React from 'react';
import {UserListingHeaderCell, UserListingHeaderRowWrapper} from '../UserListingStyles';
import {UserListingFieldType} from "../../../../Schema/Users/userListing";

export type Props = {
	userSchema: Array<UserListingFieldType>;
};

export const UserListingHeaderRowLayout = (props: Props) =>
{
	const render = () =>
	{
		const innerContent = generateHeaderCells(props.userSchema);
		const returnValue = (
			<UserListingHeaderRowWrapper>
				{innerContent}
			</UserListingHeaderRowWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};

const generateHeaderCells = (userSchema: Array<UserListingFieldType>) => {
	const returnValue = userSchema.map((item: UserListingFieldType, index: number) => {
		const align: ("left" | "inherit" | "-moz-initial" | "initial" | "revert" | "unset" | "center" | "end" | "justify" | "match-parent" | "right" | "start") = item.align ? item.align : "left";
		const innerReturnValue = (
			<UserListingHeaderCell style={{textAlign: align}} key={"userlistingcell" + index}>
				{item.title}
			</UserListingHeaderCell>
		);
		return innerReturnValue;
	})
	return returnValue;
}
