import * as React from 'react';
import { BookingListingRowWrapper } from '../BookingsListingStyles';
import { BookingsListingFieldType } from '../../../../Schema/Bookings/bookingsListing';
import { BookingsListingCellLayout } from './BookingsListingCellLayout';

export type Props = {
	booking: any;
	bookingsSchema: Array<BookingsListingFieldType>;
};

export const BookingsListingRowLayout = (props: Props) => {
	const render = () => {
		const cells = generateRowCells(props.booking, props.bookingsSchema, BookingsListingCellLayout);
		const returnValue = <BookingListingRowWrapper>{cells}</BookingListingRowWrapper>;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};

const generateRowCells = (obj: any, schema: any, RenderObject: any) => {
	const returnValue = schema.map((schemaLine: any, index: number) => {
		const outgoingProps = {
			value: obj[schemaLine['source']],
			format: schemaLine['format'],
			align: schemaLine['align'],
		};

		const innerReturnValue = <RenderObject {...outgoingProps} />;
		return innerReturnValue;
	});
	return returnValue;
};
