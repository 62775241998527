import styled from 'styled-components';
import { cssVarColors } from '../../Constants';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

export const FilterIcon = styled(FilterAltIcon)`
	color: ${cssVarColors.highlight} !important;
	font-size: 8em;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: scale(2);
`;

export const FilterOverlayWrapper = styled.div`
	border-radius: 50%;
	background-color: #fff;
	position: absolute;
	bottom: 4rem;
	right: 1rem;
	height: 5rem;
	width: 5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	&:hover {
		background-color: ${cssVarColors.highlight};
	}
	&:hover ${FilterIcon} {
		color: #fff !important;
	}
`;

export const FilterModalWrapper = styled('div')``;
