import React from 'react';

type Props = {
	Layout: any;
	todayHeading: string;
	currentAverageHeading: string;
	dayAverageHeading: string;
	data: any;
	currentDate: any;
	updateTime: string;
};

export const TrendCardContainer = (props: Props) => {
	const render = (props: Props) => {
		const Layout = props.Layout;
		const outgoingProps = {
			todayHeading: props.todayHeading,
			currentAverageHeading: props.currentAverageHeading,
			dayAverageHeading: props.dayAverageHeading,
			data: props.data,
			currentDate: props.currentDate,
			updateTime: props.updateTime,
		};
		const returnValue = <Layout {...outgoingProps} />;
		return returnValue;
	};
	const outerReturnValue = render(props);
	return outerReturnValue;
};
