export const language = {
	primaryUnit: 'Site Visits',
	shortUnit: 'Visits',
	primaryTitle: 'Today',
	secondaryUnit: '%shortUnit% / Hour',
	secondaryTitle: 'Current Avg Rate',
	thirdUnit: '%shortUnit% / Hour',
	thirdTitle: '%day% Avg Rate',
	fourthUnit: '%shortUnit% / Hour',
	fourthTitle: 'Current Inst Rate',
	fifthUnit: '%shortUnit% / Hour',
	fifthTitle: '%day% Inst Rate',
	trend: {
		todayHeading: '%primaryUnit% Today',
		currentAverageHeading: 'Average Over Last Five %weekdayOrWeekend%',
		dayAverageHeading: 'Average Over Last Five %day%s',
	},
};
