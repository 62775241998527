import * as React from 'react';
import { Props as LayoutProps } from './BookingsListingLayout';
import { BookingsListingSchema } from '../../../Schema/Bookings/bookingsListing';
import { useEffect, useState } from 'react';
import { setLoading } from '../../../ReduxApp/app/consts/duck/operations';
import { apiGet } from '../../../utils/axios/getWrapper';
import { reportingEndpoint } from '../../../Constants/endpoints';

export type Props = {
	Layout: any;
};

export const BookingsListingContainer = (props: Props) => {
	const [bookings, setBookings] = useState([]);
	const fetchData = async () => {
		const endpoint = reportingEndpoint;
		const path = '/traction/allBookings';
		setLoading(true);
		const response: any = await apiGet(endpoint, path, {}, true);
		setBookings(response.data.bookings);
		setLoading(false);
	};
	useEffect(() => {
		fetchData();
	}, []);

	const render = () => {
		const Layout = props.Layout;
		const outgoingProps: LayoutProps = {
			bookings,
			bookingsSchema: BookingsListingSchema,
		};
		const returnValue = <Layout {...outgoingProps} />;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
