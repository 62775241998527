import {styled} from "@mui/material/styles";
import {cssVarColors} from "../../Constants/cssVarColors";
import CloseIcon from "@mui/icons-material/Close";

export const MessageWrapper = styled('div')`
	display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const IconWrapper = styled('div')`
	padding-right: .25em;
`;

export const TextWrapper = styled('div')`
	padding-left: .25em;
  vertical-align: center;
`;


export const EnhancedCloseIcon = styled(CloseIcon)`
 &:hover {
   color: ${cssVarColors.error};
 }
`
